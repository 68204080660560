// /*eslint-disable*/
import React, { useState, createRef, useEffect } from "react";
import PropTypes from "prop-types";
import compose from "recompose/compose";
import { connect } from "react-redux";
import actionsForNavigation from "store/actions/navigation";
import AuthActions from 'store/actions/auth';
import { db, firebase, storage } from "firebase/client";
import UserInfo from "./components/UserInfo";
import BillingInfo from "./components/BillingInfo";
import UserInfoMobile from "./components/UserInfoMobile";
import BillingInfoMobile from "./components/BillingInfoMobile";
import { updateUserData, updateUserPhoto, updateUserLogo, deleteUserPhoto } from "store/actions/auth.js";
import moment from "moment"
import { CSVLink } from "react-csv";
import Table from "./components/Table";
import MerchantDataTable from "./components/MerchantDataTable";
import { zip } from "lodash";
import { ptsFormula } from "globalCalculationFunction/globalCalculationFunction";
import MerchantDataFilter from "./components/MerchantDataFilter";
import { reportSelections } from "../reports/reportSelections";
import Filter from "./components/Filter";
import DateRange from "./components/DateRange";
import GatewayPage from "./components/Gateway";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Hidden from "@material-ui/core/Hidden";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import { Dialog, Typography, Card, Popper, FormControl, Select, MenuItem, Checkbox } from "@material-ui/core";



// @material-ui/icons
import { ReactComponent as Edit } from "assets/img/tuzo/icons/EditIcon.svg";
import { ReactComponent as SaveIcon } from "assets/img/tuzo/icons/SaveIcon.svg";
import { ReactComponent as Delete } from "assets/img/tuzo/icons/Delete.svg";
import { ReactComponent as TrashIcon } from "assets/img/tuzo/icons/TrashIcon.svg";
import { BsCheckCircleFill } from "react-icons/bs";
import { Close, MoreVert } from '@material-ui/icons';

//styling 
import profileStyle from "assets/jss/material-dashboard-pro-react/views/profileStyle";
import tuzoBg from "assets/img/tuzo/carouselBackground.png";
import loader from "assets/img/loader.gif";



const headersDaily = [
    { label: "Date", key: "date" },
    { label: "# of Transactions", key: "numTransactions" },
    { label: "Total ($)", key: "total" },
    { label: "Reference", key: "referenceNum" },
    { label: "Points", key: "pts" },
];

const headersMonthly = [
    { label: "Date", key: "date" },
    { label: "# of Batches", key: "numBatches" },
    { label: "Total ($)", key: "total" },
    { label: "Reference", key: "referenceNum" },
    { label: "Points", key: "pts" },
];

const headersISO = [
    { label: "Points", key: "pts" },
    { label: "Name", key: "name" },
    { label: "Company Name", key: "companyName" },
    { label: "Email", key: "email" },
    { label: "Status", key: "status" },
];

const statusArr = [
    "Total Points",
    "Name",
    "Joined"
]

const ascArr = [
    "Asc",
    "Desc"
]

const userTypeArr = [
    // "Administrator",
    // "ISO",
    "Staff",
    "Merchants",
]

const entriesArr = [
    1,
    2,
    3,
    4,
    5,
    6
]

const gatewayArr = [
    {
        type: "Fiserv Access One",
        id: 0
    },
    {
        type: "IRIS",
        id: 1
    },
    {
        type: "Maverick",
        id: 2
    }
]

export function UserProfile(props) {
    const {
        classes,
        history,
        uid,
        user,
        editState,
        userCompany
    } = props;



    const currUser = firebase.auth().currentUser
    const docId = window.location.pathname.split("/")[3]
    const [dataLoaded, setDataLoaded] = useState(false)
    const [merchantDataArr, setMerchantDataArr] = useState(false)
    const [userData, setUserData] = useState("")
    const [username, setUsername] = useState("")
    const [firstNameState, setFirstNameState] = useState("")
    const [lastNameState, setLastNameState] = useState("")
    const [emailState, setEmailState] = useState("")
    const [copyEmail, setCopyEmail] = useState("")
    const [cellPhoneState, setCellPhoneState] = useState("")
    const [secondaryPhoneState, setSecondaryPhoneState] = useState("")
    const [shippingAddressState, setShippingAddressState] = useState("")
    const [companyState, setCompanyState] = useState("");
    const [birthdayState, setBirthdayState] = useState("")
    const [approvedPtsState, setApprovedPtsState] = useState(0)
    const [profilePic, setProfilePic] = useState("")
    const [progress, setProgress] = useState(0)
    const [uploading, setUploading] = useState(false)
    const [globalEdit, setGlobalEdit] = useState(editState ? editState : false)
    const [confirmDelete, setConfirmDelete] = useState(false)
    const [emailSent, setEmailSent] = useState(false)
    const [billingEdit, setBillingEdit] = useState(false)
    const [cardNumber, setCardNumber] = useState("")
    const [expDate, setExpDate] = useState("")
    const [nameOnCard, setNameOnCard] = useState("")
    const [billingAddress, setBillingAddress] = useState("")
    const [asDefault, setAsDefault] = useState(true)
    const [newPayment, setNewPayment] = useState(false)
    const [typeOfPayment, setTypeOfPayment] = useState({
        card: true,
        ach: false
    })
    const [itemsPerPage, setItemsPerPage] = useState(6);
    const [pageNumber, setPageNumber] = useState(0);
    const [index, setIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(itemsPerPage);
    const [zippedArr, setZippedArr] = useState(false)
    const [copyZippedArr, setZippedCopyArr] = useState(false)
    const [points, setPoints] = useState("")
    const [reportArr, setReportArr] = useState([])
    const [sortValue, setSortValue] = useState("")
    const [reportType, setReportType] = useState("Monthly")
    const [dateFilter, setDateFilter] = useState(moment().format("MMM DD, YYYY"))
    const [headers, setHeaders] = useState(null)
    const [reportData, setReportData] = useState([])
    const [merchDocId, setMerchDocId] = useState("")
    const [statusDropdown, setStatusDropdown] = useState("Total Points")
    const [ascDropdown, setAscDropdown] = useState("Asc")
    const [searchString, setSearchString] = useState("")
    const [copyArr, setCopyArr] = useState(false)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openPopper, setOpenPopper] = useState(false)
    const [fromDate, setFromDate] = useState("")
    const [toDate, setToDate] = useState("")
    const [isoAdminReportData, setIsoAdminReportData] = useState(false)
    const csvLinkRef = React.createRef();
    const [userTypeArrFilter, setUserTypeArrFilter] = useState([])
    const [emptyData, setEmptyData] = useState(false)
    const [gateway, setGateway] = useState("Fiserv Access One")
    const [apiEdit, setApiEdit] = useState(false)
    const [apiInfo, setApiInfo] = useState([{
        apiType: gateway,
        apiKey: "",
        apiSecret: "",
        id: 0
    }])

    const handlePageClick = ({ selected }) => {
        setPageNumber(selected);
        setIndex(selected * itemsPerPage);
        setEndIndex(selected * itemsPerPage + itemsPerPage);
    };

    const handlePopper = (event) => {
        getIsoAdminReport()
        setAnchorEl(anchorEl ? null : event.currentTarget);
        setOpenPopper(!openPopper)
    };

    const handleReportToggle = (value) => {
        setReportType(value)
    }

    const increaseDate = (date) => {
        let newDate = ""
        if (reportType === "Monthly") {
            newDate = moment(date, "MMM YYYY").add(1, 'months').format("MMM YYYY")
        }
        else {
            newDate = moment(date, "MMM DD, YYYY").add(1, 'd').format("MMM DD, YYYY")
        }

        setDateFilter(newDate)
    }

    const decreaseDate = (date) => {
        let newDate = ""
        if (reportType === "Monthly") {
            newDate = moment(date, "MMM YYYY").subtract(1, 'months').format("MMM YYYY")
        }
        else {
            newDate = moment(date, "MMM DD, YYYY").subtract(1, 'd').format("MMM DD, YYYY")
        }
        setDateFilter(newDate)
    }

    useEffect(() => {
        if (reportType === "Monthly") {
            setDateFilter(moment(dateFilter).format("MMM YYYY"))
            setHeaders(headersMonthly)
        }
        else {
            setDateFilter(moment(dateFilter).format("MMM DD, YYYY"))
            setHeaders(headersDaily)
        }

    }, [reportType])

    const handleSearch = () => {
        if (searchString) {
            const filtered = copyArr.filter((f) => {
                if (
                    f.firstName.toLowerCase().includes(searchString.toLowerCase()) ||
                    f.lastName.toLowerCase().includes(searchString.toLowerCase()) ||
                    f.companyName.toLowerCase().includes(searchString.toLowerCase()) ||
                    f.email.toLowerCase().includes(searchString.toLowerCase())
                ) {
                    return true
                }
            })
            if(filtered.length === 0){
                setEmptyData(true)
            }
            setMerchantDataArr(filtered)
        } else {
            setMerchantDataArr(copyArr)
        }
    }

    const resetEmptyData = () => {
        setEmptyData(false)
        setSearchString("")
        setMerchantDataArr(copyArr)
    }

    const resetPageFilter = (filterArr) => {
        setZippedArr([...filterArr])
        setIndex(0)
        setEndIndex(itemsPerPage)
        setPageNumber(0)
    }

    const resetPageFilterISO = () => {
        setIndex(0)
        setEndIndex(itemsPerPage)
        setPageNumber(0)
    }

    const handleFilter = (ascValue, sortValue) => {
        if (ascValue === "Asc") {
            if (sortValue === "Total Points") {
                const filtered = merchantDataArr.sort((a, b) =>
                    (a.tuzoPoints != null ? a.tuzoPoints : -Infinity) - (b.tuzoPoints != null ? b.tuzoPoints : -Infinity)
                )
                resetPageFilter(filtered)
            }
            if (sortValue === "Name") {
                const filtered = merchantDataArr.sort(function (a, b) {
                    if (a.contact) {
                        if (a.contact < b.contact) { return -1; }
                        if (a.contact > b.contact) { return 1; }
                        return 0;
                    } else {
                        if (a.lastName < b.lastName) { return -1; }
                        if (a.lastName > b.lastName) { return 1; }
                        return 0;
                    }

                })
                resetPageFilter(filtered)
            }
            if (sortValue === "Joined") {
                const filtered = merchantDataArr.sort(function (a, b) {
                    return (a.timestamp != null ? a.timestamp.seconds : -Infinity) -
                        (b.timestamp != null ? b.timestamp.seconds : -Infinity);
                })
                resetPageFilter(filtered)
            }
        } if (ascValue === "Desc") {
            if (sortValue === "Total Points") {
                const filtered = merchantDataArr.sort((a, b) =>
                    (b.approvedPts != null ? b.approvedPts : -Infinity) - (a.approvedPts != null ? a.approvedPts : -Infinity)
                )
                resetPageFilter(filtered)
            }
            if (sortValue === "Name") {
                const filtered = merchantDataArr.sort(function (a, b) {
                    if (a.contact) {
                        if (a.contact > b.contact) { return -1; }
                        if (a.contact < b.contact) { return 1; }
                        return 0;
                    } else {
                        if (a.lastName > b.lastName) { return -1; }
                        if (a.lastName < b.lastName) { return 1; }
                        return 0;
                    }

                })
                resetPageFilter(filtered)
            }
            if (sortValue === "Joined") {
                const filtered = merchantDataArr.sort(function (a, b) {
                    return (b.timestamp != null ? b.timestamp.seconds : -Infinity) -
                        (a.timestamp != null ? a.timestamp.seconds : -Infinity);
                })
                resetPageFilter(filtered)
            }
        }
    }

    const handleUserTypeFilter = (event) => {
        let userArr = []
        const {
            target: { value },
        } = event;
        setUserTypeArrFilter(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
        if (value.length === 0) {
            setMerchantDataArr(copyArr)
        } else {
            copyArr.map((data) => {
                if (
                    value.includes("Administrator") &&
                    (data.userType === "Administrator" || data.userType === "Admin")
                ) {
                    userArr.push(data)
                }
                else if (
                    value.includes("ISO") && data.userType === "IsoAdmin"
                ) {

                    userArr.push(data)
                }
                else if (
                    (data.userType.includes("Merchant") && value.includes("Merchants"))
                ) {

                    userArr.push(data)
                }
                else {
                    if (value.includes(data.userType)) {
                        userArr.push(data)
                    }
                }
            })
            if (ascDropdown === "Asc") {
                userArr.sort(function (a, b) {
                    if (a.userType < b.userType) { return -1; }
                    if (a.userType > b.userType) { return 1; }
                    return 0;
                })
                resetPageFilterISO()
                setMerchantDataArr(userArr)
            }
            if (ascDropdown === "Desc") {
                userArr.sort(function (a, b) {
                    if (a[0].userType > b[0].userType) { return -1; }
                    if (a[0].userType < b[0].userType) { return 1; }
                    return 0;
                })
                resetPageFilterISO()
                setMerchantDataArr(userArr)
            }
        }
    }

    useEffect(() => {
        if (merchantDataArr) {
            if (reportType === "Monthly") {
                const filteredDate = merchantDataArr.filter((b) => {
                    if (b.reportDate >= moment(dateFilter).startOf('month').format("YYYY-MM-DDT00:00:00")
                        && b.reportDate <= moment(dateFilter).endOf('month').format("YYYY-MM-DDT23:59:59")
                    ) {
                        return true;
                    }
                })
                setReportData(filteredDate)
            }
            else if (reportType === "Daily") {
                const filteredDate = merchantDataArr.filter((b) => {
                    if (b.reportDate >= moment(dateFilter).startOf('day').format("YYYY-MM-DDT00:00:00")
                        && b.reportDate <= moment(dateFilter).endOf('day').format("YYYY-MM-DDT23:59:59")
                    ) {
                        return true;
                    }
                })
                setReportData(filteredDate)
            }

            else {
                setReportData([])
            }
        }

    }, [dateFilter, merchantDataArr])

    useEffect(() => {
        if (progress === 100) {
            setUploading(false);
        }
    }, [progress]);

    function formatPhoneNumber(phoneNumber) {
        var cleaned = ("" + phoneNumber).replace(/\D/g, "");
        var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return "(" + match[1] + ") " + match[2] + "-" + match[3];
        }
        return "";
    }

    const updateUserInfo = (e) => {
        e.preventDefault();
        var newInfo = {}
        if (userData.userType === "IsoAdmin") {
            newInfo = {
                username: username,
                firstName: firstNameState,
                lastName: lastNameState,
                companyName: companyState,
                birthday: birthdayState,
                email: emailState,
                cellPhone: cellPhoneState,
                secondaryPhone: secondaryPhoneState,
                shippingAddress: shippingAddressState,
                apiInfo
            };
        } else {
            newInfo = {
                username: username,
                firstName: firstNameState,
                lastName: lastNameState,
                companyName: companyState,
                birthday: birthdayState,
                email: emailState,
                cellPhone: cellPhoneState,
                secondaryPhone: secondaryPhoneState,
                shippingAddress: shippingAddressState,
            }
        }
        props.updateUserData(docId, newInfo).then(() => {
            setGlobalEdit(false)
        })
    };

    const handleImageUpload = (event) => {
        var previewUrl = URL.createObjectURL(event.target.files[0]);
        var file = event.target.files[0];
        const storageRef = storage.ref(uid);
        const fileRef = storageRef.child("profilePic");

        var uploadTask = fileRef.put(file);

        // fileRef.put(file).then((snapshot) => {
        setUploading(true)

        uploadTask.on(
            "state_changed",
            (snapshot) => {
                // Observe state change events such as progress, pause, and resume
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const progress =
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setProgress(progress);
                switch (snapshot.state) {
                    case "paused":
                        console.log("Upload is paused");
                        break;
                    case "running":
                        console.log("Upload is running");
                        break;
                }
            },
            (error) => {
                // Handle unsuccessful uploads
            },
            (snapshot) => {
                uploadTask.snapshot.ref
                    .getDownloadURL()
                    .then((downloadURL) => {
                        console.log("downloadURL", downloadURL);
                        setProfilePic(downloadURL)
                        props.updateUserPhoto(docId, { url: downloadURL, });
                    })
                    .catch(() => { });
            }
        );
    };

    const getBgImage = () => {
        return tuzoBg;
    }

    const deleteImage = () => {
        props.deleteUserPhoto(docId)
        setProfilePic("")
        setConfirmDelete(false)
    }

    const handlePasswordReset = (e) => {
        e.preventDefault()
        props.startPasswordReset(emailState)
            .then(() => {
                setEmailSent(true)
            })

    }

    const getIsoAdminReport = () => {
        let arr = []
        let pendArr = []
        let combineArr = []
        let docArr = []
        let filtered = []

        return db
            .collection('users')
            .where("IsoAdminCompanyName", "==", userData.companyName)
            .get()
            .then((snapshots) => {
                if (!snapshots.empty) {
                    snapshots.forEach((s) => {
                        s = s.data()
                        arr.push(s)
                    })
                    // setData(arr)
                }
                else {
                    // setData([])
                }
                db
                    .collection('iso-temp-user')
                    .where("IsoAdminCompanyName", "==", userData.companyName)
                    .get()
                    .then((snapshots) => {
                        if (!snapshots.empty) {
                            snapshots.forEach((s) => {
                                s = s.data()
                                pendArr.push(s)
                            })
                            combineArr = [...arr, ...pendArr]
                            // setData(combineArr)
                        }
                        else {
                            combineArr = [...arr, ...pendArr]
                            // setData(combineArr)
                        }
                        for (let i = 0; i < combineArr.length; i++) {
                            if (combineArr[i].merchantId) {
                                db
                                    .collection('merchants-live')
                                    .where("merchantNumber", "==", combineArr[i].merchantId)
                                    .get()
                                    .then((snapshots) => {
                                        if (!snapshots.empty) {
                                            snapshots.docs.forEach((doc) => {
                                                db
                                                    .collection('merchants-live')
                                                    .doc(doc.data().docId)
                                                    .collection("batch-summary")
                                                    .get()
                                                    .then((subSnapshot) => {
                                                        if (!subSnapshot.empty) {
                                                            subSnapshot.docs.forEach((subDoc) => {
                                                                subDoc = subDoc.data()
                                                                let merchantName = combineArr[i].companyName
                                                                let email = combineArr[i].email
                                                                let userBasisPts = combineArr[i].userBasisPts
                                                                let newObj = {
                                                                    ...subDoc,
                                                                    merchantName,
                                                                    email,
                                                                    parentDocId: doc.data().docId,
                                                                    userBasisPts
                                                                }
                                                                docArr.push(newObj)
                                                            })
                                                            setIsoAdminReportData(docArr)
                                                        } else {
                                                            setIsoAdminReportData([])
                                                        }


                                                    })

                                            })
                                        }
                                        else {
                                            setIsoAdminReportData([])
                                        }
                                    })
                            }
                        }

                    })
            })
    }



    const getMerchantReport = () => {
        let subId = ""
        return db
            .collection('merchants-live')
            .where("merchantNumber", "==", userData.merchantId)
            .get()
            .then((snapshots) => {
                if (!snapshots.empty) {
                    snapshots.forEach((s) => {
                        s = s.data()
                        subId = s.docId
                        setMerchDocId(s.docId)
                    })
                    db
                        .collection("merchants-live")
                        .doc(subId)
                        .collection("batch-summary")
                        .orderBy('reportDate', 'desc')
                        .onSnapshot((snapshot) => {
                            const arr = [];
                            snapshot.forEach((doc) => {
                                arr.push(doc.data())
                            })
                            setMerchantDataArr(arr)
                            setReportData(arr)
                        })
                }
                else {
                    setMerchantDataArr([])
                    setReportData([])
                }
            })
    }

    const getMerchantArrData = () => {
        let arr = []
        let pendArr = []
        let combineArr = []
        return db
            .collection('users')
            .where("IsoAdminCompanyName", "==", userData.companyName)
            .get()
            .then((snapshots) => {
                if (!snapshots.empty) {
                    snapshots.forEach((s) => {
                        s = s.data()
                        arr.push(s)
                    })
                    setMerchantDataArr(arr)
                    setCopyArr(arr)
                }
                else {
                    setMerchantDataArr(arr)
                    setCopyArr(arr)
                }
                db.collection('temp-user')
                    .where("IsoAdminCompanyName", "==", userData.companyName)
                    .get()
                    .then((snapshots) => {
                        if (!snapshots.empty) {
                            snapshots.forEach((s) => {
                                s = s.data()
                                let newObj = {
                                    ...s,
                                    status: "Pending"
                                }
                                pendArr.push(newObj)
                            })
                            setMerchantDataArr(pendArr)
                            setCopyArr(pendArr)
                        }
                        else {
                            setMerchantDataArr(pendArr)
                            setCopyArr(pendArr)
                        }
                    })
                db.collection('iso-temp-user')
                    .where("IsoAdminCompanyName", "==", userData.companyName)
                    .get()
                    .then((snapshots) => {
                        if (!snapshots.empty) {
                            snapshots.forEach((s) => {
                                s = s.data()
                                let newObj = {
                                    ...s,
                                    status: "Pending"
                                }
                                pendArr.push(newObj)
                            })
                            combineArr = [...arr, ...pendArr]
                            setMerchantDataArr(combineArr)
                            setCopyArr(combineArr)
                        }
                        else {
                            combineArr = [...arr, ...pendArr]
                            setMerchantDataArr(combineArr)
                            setCopyArr(combineArr)
                        }
                    })
            })
    }

    useEffect(() => {
        if (userData && userData.userType === "IsoAdmin") {
            getMerchantArrData()
        }
        if (userData && userData.userType.includes("Merchants")) {
            getMerchantReport()
            reportSelections.map((data) => {
                if (data.type === "Merchants" && userData.userType.includes("Merchants")) {
                    setReportArr(prevArr => [...prevArr, data])
                }
            })
        }
    }, [userData.userType, companyState])

    useEffect(() => {
        if (reportData) {
            var arr = []
            setPoints([])
            reportData.map((d) => {
                const floorDollarPerBassisPts = ptsFormula(d.netAmount, userData.userBasisPts)
                arr.push(floorDollarPerBassisPts)
                setPoints(prevArr => [...prevArr, floorDollarPerBassisPts])
            })
        }
        setZippedArr(zip(reportData, arr))
        setZippedCopyArr(zip(reportData, arr))
    }, [reportData])


    const getData = () => {
        db.collection('users').doc(docId).get()
            .then((snapshots) => {
                if (snapshots.exists) {
                    let data = snapshots.data()
                    setUserData(data)
                    setUsername(data.username ? data.username : "")
                    setFirstNameState(data.firstName ? data.firstName : "")
                    setLastNameState(data.lastName ? data.lastName : "")
                    setEmailState(data.email ? data.email : "")
                    setCellPhoneState(data.cellPhone ? data.cellPhone : "")
                    setSecondaryPhoneState(data.secondaryPhone ? data.secondaryPhone : "")
                    setShippingAddressState(data.shippingAddress ? data.shippingAddress : "")
                    setCompanyState(data.companyName ? data.companyName : "")
                    setBirthdayState(data.birthday ? data.birthday : "")
                    setApprovedPtsState(data.approvedPts ? data.approvedPts : 0)
                    setProfilePic(data.profilePic ? data.profilePic : "")
                    setApiInfo(data.apiInfo ? data.apiInfo : apiInfo)
                } else {
                    console.log("checking empty")
                }

            })
            .then(() => {
                setDataLoaded(true)
            })
    }

    useEffect(() => {
        getData()
    }, [])



    if (!dataLoaded ||
        (userData && (userData.userType === "IsoAdmin" || userData.userType.includes("Merchants"))
            && !merchantDataArr)
    )
        return (
            <div
                style={{
                    width: "100%",
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <img style={{ width: "20%" }} src={loader} alt="..." />
            </div>
        );

    return (
        <div>
            <Hidden smDown implementation="css">
                <GridContainer >
                    <GridItem xs={8} style={{ display: "flex" }}>
                        <Typography variant="h2" className={classes.headerTitle}>
                            {firstNameState + " " + lastNameState + "  "}
                        </Typography>
                        {billingEdit && !globalEdit ?
                            null
                            :
                            !globalEdit ?
                                <>
                                    <Button
                                        onClick={() => setGlobalEdit(true)}
                                        className={classes.editButton}
                                    >
                                        <Edit style={{ marginRight: "10px", marginTop: "-5px" }} /> Edit
                                    </Button>

                                    <Button
                                        onClick={handlePasswordReset}
                                        className={classes.editButton}
                                        style={{ background: "#56CA93", width: "350px" }}
                                    >
                                        <BsCheckCircleFill style={{ marginRight: "10px", marginTop: "-5px" }} /> Send Reset Password Email
                                    </Button>
                                </>
                                :
                                <div>
                                    <Button
                                        className={classes.editButton}
                                        style={{ background: "#1A405F", width: "128px" }}
                                        onClick={updateUserInfo}
                                    >
                                        <SaveIcon style={{ marginRight: "10px", marginTop: "-5px" }} /> Save
                                    </Button>
                                    <Button
                                        onClick={() => setGlobalEdit(false)}
                                        className={classes.editButton}
                                        style={{ background: "#E2A400", width: "143px" }}
                                    >
                                        <Delete style={{ marginRight: "10px", marginTop: "-5px" }} /> Cancel
                                    </Button>
                                </div>
                        }
                    </GridItem>

                    <GridItem xs={4} style={{ display: "flex", justifyContent: "flex-end" }}>
                        <div style={{ display: "flex", alignItems: "center", flexDirection: "column", marginTop: "-10px", marginRight: "30px" }}>
                            <Typography variant="h2" className={classes.headerDesc}>
                                User Type
                            </Typography>
                            <Typography variant="h2" className={classes.headerDesc} style={{ fontWeight: 500, fontSize: "18px", lineHeight: "18px", marginTop: "10px" }}>
                                {userData.userType.includes("Merchant") ? "Merchant" : userData.userType}
                            </Typography>
                        </div>

                        {userData.userType.includes("Merchant") ?
                            <>
                                <div style={{ display: "flex", alignItems: "center", flexDirection: "column", marginTop: "-10px", marginRight: "30px" }}>
                                    <Typography variant="h2" className={classes.headerDesc} style={{ whiteSpace: "nowrap" }}>
                                        Merchant ID
                                    </Typography>
                                    <Typography variant="h2" className={classes.headerDesc} style={{ fontWeight: 500, fontSize: "18px", lineHeight: "18px", marginTop: "10px" }}>
                                        {userData.merchantId}
                                    </Typography>
                                </div>
                            </>
                            : null}

                        <div style={{ display: "flex", alignItems: "center", flexDirection: "column", marginTop: "-10px" }}>
                            <Typography variant="h2" className={classes.headerDesc}>
                                Account Number
                            </Typography>
                            <Typography variant="h2" className={classes.headerDesc} style={{ fontWeight: 500, fontSize: "18px", lineHeight: "18px", marginTop: "10px" }}>
                                {docId}
                            </Typography>
                        </div>

                    </GridItem>
                </GridContainer>

                <div
                    style={{
                        backgroundImage: "url(" + getBgImage() + ")",
                        width: "100vw",
                        marginLeft: "-30px",
                        height: "130px",
                        marginTop: "15px",
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "#274b73",
                        backgroundPhoto: "no-repeat",
                        backgroundSize: "contain"
                    }}
                >
                    <GridContainer style={{ alignItems: "center" }}>
                        <GridItem xs={4} style={{ textAlign: "left", marginTop: "10px" }}>
                            <Typography variant="h2" className={classes.profileBannerTextBreakSpace}>
                                User has <span className={classes.profileBannerPts}> {(approvedPtsState).toLocaleString()} </span> Tuzo points now
                                and <span className={classes.profileBannerPts}> {userData.tuzoPoints ? (userData.tuzoPoints).toLocaleString() : 0} </span> pending!
                            </Typography>
                        </GridItem>

                        <GridItem xs={4} style={{ textAlign: "center" }}>
                            <Typography variant="h2" className={classes.profileBannerText} style={{ textAlign: "left", lineHeight: "30px" }}>
                                Based on the average monthly processing ({(approvedPtsState).toLocaleString()}), they’re on track to have:
                            </Typography>
                        </GridItem>

                        <GridItem xs={4} style={{ display: "flex", justifyContent: "space-evenly", textAlign: "center" }}>

                            <div>
                                <Typography variant="h2" className={classes.profileBannerDurationText}>
                                    1 Month:
                                </Typography>
                                <Typography variant="h2" className={classes.profileBannerDurationPts}>
                                    {(approvedPtsState.toLocaleString())}
                                </Typography>
                            </div>

                            <div>
                                <Typography variant="h2" className={classes.profileBannerDurationText}>
                                    1 Year:
                                </Typography>
                                <Typography variant="h2" className={classes.profileBannerDurationPts}>
                                    {(approvedPtsState * 12).toLocaleString()}
                                </Typography>
                            </div>

                            <div>
                                <Typography variant="h2" className={classes.profileBannerDurationText}>
                                    3 Years:
                                </Typography>
                                <Typography variant="h2" className={classes.profileBannerDurationPts}>
                                    {(approvedPtsState * 36).toLocaleString()}
                                </Typography>
                            </div>
                        </GridItem>

                    </GridContainer>
                </div>

                <GridContainer >
                    <GridItem xs={12} style={{ marginTop: "20px" }}>
                        <UserInfo
                            username={username}
                            setUsername={setUsername}
                            user={user}
                            profilePic={profilePic}
                            firstNameState={firstNameState}
                            setFirstNameState={setFirstNameState}
                            lastNameState={lastNameState}
                            setLastNameState={setLastNameState}
                            companyState={companyState}
                            setCompanyState={setCompanyState}
                            birthdayState={birthdayState}
                            setBirthdayState={setBirthdayState}
                            emailState={emailState}
                            setEmailState={setEmailState}
                            cellPhoneState={cellPhoneState}
                            setCellPhoneState={setCellPhoneState}
                            secondaryPhoneState={secondaryPhoneState}
                            setSecondaryPhoneState={setSecondaryPhoneState}
                            shippingAddressState={shippingAddressState}
                            setShippingAddressState={setShippingAddressState}
                            globalEdit={globalEdit}
                            handleImageUpload={handleImageUpload}
                            uploading={uploading}
                            progress={progress}
                            deleteImage={deleteImage}
                            formatPhoneNumber={formatPhoneNumber}
                            confirmDelete={confirmDelete}
                            setConfirmDelete={setConfirmDelete}
                            userData={userData}
                        />
                    </GridItem>

                    {userData.userType !== "IsoAdmin" ?
                        <GridItem xs={12} >
                            <BillingInfo
                                globalEdit={globalEdit}
                                billingEdit={billingEdit}
                                setBillingEdit={setBillingEdit}
                                cardNumber={cardNumber}
                                setCardNumber={setCardNumber}
                                expDate={expDate}
                                setExpDate={setExpDate}
                                nameOnCard={nameOnCard}
                                setNameOnCard={setNameOnCard}
                                billingAddress={billingAddress}
                                setBillingAddress={setBillingAddress}
                                asDefault={asDefault}
                                setAsDefault={setAsDefault}
                                newPayment={newPayment}
                                setNewPayment={setNewPayment}
                                typeOfPayment={typeOfPayment}
                                setTypeOfPayment={setTypeOfPayment}
                            />
                        </GridItem>
                        :
                        <GridItem xs={8}>
                            <GatewayPage
                                gatewayArr={gatewayArr}
                                gateway={gateway}
                                setGateway={setGateway}
                                globalEdit={globalEdit}
                                apiEdit={apiEdit}
                                setApiEdit={setApiEdit}
                                apiInfo={apiInfo}
                                setApiInfo={setApiInfo}
                            />
                        </GridItem>
                    }

                    {userData.userType === "IsoAdmin" ?
                        <>
                            <GridItem xs={12} style={{ marginTop: "15px" }}>
                                <Card className={classes.profileCardStyling}>
                                    <GridContainer>
                                        <GridItem xs={4}
                                            style={{
                                                alignItems: "center",
                                                display: "flex",
                                                marginBottom: "10px"
                                            }}
                                        >
                                            <Typography variant="h2" className={classes.cardHeader}>
                                                User Table
                                            </Typography>

                                            {userTypeArrFilter.length > 0 ?
                                                <Typography
                                                    className={classes.cardHeader}
                                                    style={{ marginLeft: "10px" }}
                                                >
                                                    {userTypeArrFilter.length < 4 && merchantDataArr.length > 0 ?
                                                        " " + merchantDataArr.length + " " + userTypeArrFilter
                                                        :
                                                        null
                                                    }
                                                </Typography>
                                                : null
                                            }
                                        </GridItem>

                                        <GridItem xs={4} style={{ marginBottom: "10px" }}>
                                            <div>
                                                <FormControl style={{ margin: 1, width: "100%" }}>
                                                    <Select
                                                        style={{ width: "100%" }}
                                                        className={classes.selectMenuSort}
                                                        value={userTypeArrFilter}
                                                        disableUnderline
                                                        classes={{
                                                            root: classes.selectText,
                                                        }}
                                                        MenuProps={{
                                                            anchorOrigin: {
                                                                vertical: "bottom",
                                                                horizontal: "left"
                                                            },
                                                            transformOrigin: {
                                                                vertical: "top",
                                                                horizontal: "left"
                                                            },
                                                            getContentAnchorEl: null
                                                        }}
                                                        multiple
                                                        displayEmpty
                                                        renderValue={(selected) => {
                                                            if (selected.length === 0) {
                                                                return <p className={classes.selectPlaceHolder}>User Type</p>;
                                                            }
                                                            return selected.join(', ');
                                                        }}
                                                        onChange={handleUserTypeFilter}
                                                    >
                                                        <MenuItem
                                                            disabled
                                                            value=""
                                                            classes={{
                                                                root: classes.selectPlaceHolder,
                                                            }}
                                                        >
                                                            <p
                                                            >
                                                                User Type
                                                            </p>
                                                        </MenuItem>
                                                        {userTypeArr.map((o, i) => {
                                                            return (
                                                                <MenuItem
                                                                    key={o}
                                                                    value={o}
                                                                    classes={{
                                                                        root: classes.selectText,
                                                                    }}
                                                                >
                                                                    <Checkbox
                                                                        style={{ color: "#1A405F" }}
                                                                        checked={userTypeArrFilter.indexOf(o) > -1}
                                                                    />
                                                                    {o}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </GridItem>

                                        <GridItem
                                            xs={4}
                                            style={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                                marginBottom: "10px"
                                            }}
                                        >
                                            <Button
                                                className={classes.uploadButton}
                                                style={{ width: "50px", marginTop: "5px", height: "30px" }}
                                                onClick={handlePopper}
                                            >
                                                <MoreVert />
                                            </Button>
                                            <Popper open={openPopper} anchorEl={anchorEl} id={'transitions-popper'}>
                                                <Card className={classes.profileCardStyling} style={{ padding: "10px 20px", width: "800px" }}>
                                                    <GridContainer>
                                                        <GridItem xs={12} style={{ display: "flex", justifyContent: "flex-end" }}>
                                                            <Button
                                                                className={classes.closeButton}
                                                                onClick={(event) => {
                                                                    setAnchorEl(anchorEl ? null : event.currentTarget)
                                                                    setOpenPopper(!openPopper)
                                                                }}
                                                            >
                                                                <Close />
                                                            </Button>
                                                        </GridItem>
                                                        <GridItem xs={12}>
                                                            <Typography variant="h2" className={classes.popperTitle}>
                                                                Select A Date Range Before Report Download
                                                            </Typography>
                                                        </GridItem>
                                                        <GridItem xs={12}>
                                                            <DateRange
                                                                fromDate={fromDate}
                                                                setFromDate={setFromDate}
                                                                toDate={toDate}
                                                                setToDate={setToDate}
                                                                getIsoAdminReport={getIsoAdminReport}
                                                                csvData={isoAdminReportData}
                                                                csvLinkRef={csvLinkRef}
                                                            />
                                                        </GridItem>
                                                    </GridContainer>
                                                </Card>
                                            </Popper>
                                        </GridItem>
                                        <GridItem xs={12}>
                                            <Filter
                                                statusDropdown={statusDropdown}
                                                setStatusDropdown={setStatusDropdown}
                                                ascDropdown={ascDropdown}
                                                setAscDropdown={setAscDropdown}
                                                searchString={searchString}
                                                setSearchString={setSearchString}
                                                statusArr={statusArr}
                                                ascArr={ascArr}
                                                itemsPerPage={itemsPerPage}
                                                setItemsPerPage={setItemsPerPage}
                                                entriesArr={entriesArr}
                                                handleSearch={handleSearch}
                                                csvData={merchantDataArr}
                                                header={headersISO}
                                                setEndIndex={setEndIndex}
                                                handleFilter={handleFilter}
                                                resetEmptyData={resetEmptyData}
                                                emptyData={emptyData}
                                            />
                                        </GridItem>

                                        <GridItem xs={12}>
                                            <Table
                                                data={merchantDataArr}
                                                pageNumber={pageNumber}
                                                setPageNumber={setPageNumber}
                                                index={index}
                                                setIndex={setIndex}
                                                endIndex={endIndex}
                                                setEndIndex={setEndIndex}
                                                itemsPerPage={itemsPerPage}
                                                handlePageClick={handlePageClick}
                                                history={history}
                                                copyArr={copyArr}
                                            />
                                        </GridItem>
                                    </GridContainer>
                                </Card>
                            </GridItem>
                        </>
                        :
                        userData.userType.includes("Merchants") ?
                            <GridItem xs={12} style={{ marginTop: "15px" }}>
                                <Card className={classes.profileCardStyling}>
                                    <GridContainer>
                                        <GridItem xs={12} style={{ marginBottom: "10px" }}>
                                            <Typography variant="h2" className={classes.cardHeader}>
                                                Merchant Report
                                            </Typography>
                                        </GridItem>
                                        <GridItem xs={12}>
                                            <MerchantDataFilter
                                                sortValue={sortValue}
                                                setSortValue={setSortValue}
                                                handleReportToggle={handleReportToggle}
                                                reportType={reportType}
                                                setReportType={setReportType}
                                                dateFilter={dateFilter}
                                                setDateFilter={setDateFilter}
                                                increaseDate={increaseDate}
                                                decreaseDate={decreaseDate}
                                                reportSelections={reportArr}
                                                headers={headers}
                                                csvData={reportData}
                                                points={points}
                                            />
                                        </GridItem>

                                        <GridItem xs={12}>
                                            <MerchantDataTable
                                                data={zippedArr}
                                                pageNumber={pageNumber}
                                                setPageNumber={setPageNumber}
                                                index={index}
                                                setIndex={setIndex}
                                                endIndex={endIndex}
                                                setEndIndex={setEndIndex}
                                                itemsPerPage={itemsPerPage}
                                                handlePageClick={handlePageClick}
                                                history={history}
                                                headers={headers}
                                                docId={merchDocId}
                                            />
                                        </GridItem>
                                    </GridContainer>

                                </Card>
                            </GridItem>
                            : null}
                </GridContainer>
            </Hidden>

            <Dialog
                className={classes.requestModal}
                open={emailSent}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <Card className={classes.confirmModalCard}>
                    <GridContainer >
                        <GridItem xs={12} >
                            <h5 className={classes.cardHeader}>Email has been sent to user to reset their password. </h5>
                        </GridItem>
                        <GridItem xs={12} style={{ justifyContent: "center", display: "flex" }}>
                            <Button
                                className={classes.uploadButton}
                                onClick={() => {
                                    setEmailSent(false)
                                    history.push('/admin/dashboard')
                                }}
                            >
                                <Close style={{ marginRight: "10px" }} />  Close
                            </Button>
                        </GridItem>
                    </GridContainer>
                </Card>
            </Dialog>
        </div>
    );
}


UserProfile.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state, location) {
    return {
        editState: location.history.location.state?.editState,
    };
}

function mapDispatchToProps(actions) {
    return (dispatch) => ({
        edit: (key) =>
            dispatch(
                actionsForNavigation.openChild("Profile Editor", `/admin/user/editor`)
            ),
        updateUserData: (uid, newInfo) => dispatch(updateUserData(uid, newInfo)),
        updateUserPhoto: (id, document) => dispatch(updateUserPhoto(id, document)),
        deleteUserPhoto: (id) => dispatch(deleteUserPhoto(id)),
        updateUserLogo: (id, document) => dispatch(updateUserLogo(id, document)),
        startPasswordReset: (email) => dispatch(AuthActions.startPasswordReset(email)),
        logout: () => dispatch(AuthActions.startLogout()),
    });
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(profileStyle)
)(UserProfile);
