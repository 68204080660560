const isoAdminDashboardStyle = (theme) => ({
  dashboardTitle: {
    fontFamily: "Josefin Sans",
    fontWeight: 700,
    fontSize: "22px",
    textTransform: "capitalize",
    color: "#1A405F"
  },
  addButton: {
    width: "157px",
    fontFamily: "Josefin Sans",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "16px",
    background: "#E2A400",
    color: "#FFFFFF",
    textTransform: "capitalize",
    borderRadius: "7px",
    "&:hover": {
      background: "#E2A400",
    },
  },
  actionButton: {
    fontFamily: "Josefin Sans",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "16px",
    background: "#E2A400",
    color: "#FFFFFF",
    textTransform: "capitalize",
    borderRadius: "7px",
    "&:hover": {
      background: "#E2A400",
    },
    marginRight: "10px"
  },
  card: {
    marginBottom: "5px",
    marginTop: "0px",
    height: "102px",
    fontFamily: "Josefin Sans",
    fontWeight: 400,
    fontSize: "16px",
    textTransform: "capitalize",
    padding: "5px 10px 10px 10px",
    // width: "100%",
    whiteSpace: "noWrap",
    borderRadius: "7px",
    boxShadow: "0px 2px 2px 0px #DADADA",
    color: "#1A405F"
  },
  circleChartDesc: {
    fontFamily: "Josefin Sans",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "22px",
    whiteSpace: "pre",
    color: "#1A405F"
  },
  circleChartLabel: {
    fontFamily: "Josefin Sans",
    fontWeight: 600,
    fontSize: "14px",
    // marginTop: "15px",
    whiteSpace: "pre",
    color: "#004253",
    marginLeft: "5px"
  },
  cardTable: {
    fontFamily: "Josefin Sans",
    padding: "10px",
    boxShadow: "0px 2px 2px 0px #DADADA",
    borderRadius: "5px",
    border: "0.5px solid #56CA93"
  },
  selectMenuItem: {
    fontFamily: "Josefin Sans",
    color: "#1A405F",
    fontWeight: "500",
    fontSize: "16px"
  },
  selectMenuSort: {
    fontFamily: "Josefin Sans",
    color: "#1A405F",
    background: "#FFFFFF",
    border: "2px solid #1A405F",
    borderRadius: "7px",
    width: "40%",
    height: "45px",
    textAlign: "center",
    fontWeight: "500",
    margin: "0px 15px 0px 10px",
  },
  selectAsc: {
    color: "#1A405F",
    fontFamily: "Josefin Sans",
    background: "#FFFFFF",
    borderRadius: "7px",
    border: "2px solid #1A405F",
    width: "18%",
    height: "45px",
    textAlign: "center",
    fontWeight: "500",
    margin: "0px 15px 0px 10px",
  },
  toggleDiv: {
    display: "flex",
    width: "100%",
    border: "2px solid #1A405F",
    // filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
    borderRadius: "5px",
  },
  toggleSelection: {
    height: "35px",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: "100%",
    fontFamily: "Josefin Sans",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "13px",
    lineHeight: "15px",
    textAlign: "center",
    textTransform: "capitalize",
    color: "#1A405F",
    padding: "10px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  input: {
    fontFamily: "Josefin Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "17px",
    color: "#1A405F"
  },
  searchBar: {
    width: "100%",
    background: "white",
    height: "45px",
    fontFamily: "Josefin Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "17px",
    border: "2px solid #1A405F",
    paddingLeft: "10px",
    borderRadius: "7px",
    color: "#1A405F"
  },
  adournedEnd: {
    paddingRight: "0px !important",
    margin: "0px !important",
    maxHeight: "none !important",
    height: "40px"
  },
  searchButton: {
    borderTopRightRadius: "5px",
    borderBottomRightRadius: "7px",
    background: "#1A405F",
    // display: "block",
    margin: "0px -1px !important",
    padding: "8px 14px !important",
    height: "45px",
    width: "120px",
    color: "#FFFFFF",
    fontFamily: "Josefin Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "17px",
    textTransform: "capitalize",
    "&:hover, &:active, &:focus": {
      background: "#1A405F",
    },
  },
  rewardsTable: {
    width: "100% !important",
    borderCollapse: "collapse",
    padding: "0px 30px 10px 20px"
    // [theme.breakpoints.down("md")]: {
    //   tableLayout: "fixed",
    //   width: "100%",
    // },
  },
  tableRow: {
    height: "39px",
    background: "#FFFFFF !important"
  },
  tableRowOdd: {
    height: "39px",
    background: "#E8EBEF  !important"
  },
  tableHeaders: {
    border: "0.5px solid #1A405F",
    background: "#1A405F",
    color: "#FFFFFF",
    fontFamily: "Josefin Sans",
    fontSize: "17px",
    padding: "10px",
    textTransform: "uppercase",
  },
  tableCell: {
    textAlign: "center",
    border: "0.5px solid #1A405F",
    padding: "10px 20px",
    fontFamily: "Josefin Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    color: "#1A405F",

  },
  indexes: {
    display: "flex",
    justifyContent: "flex-end",
    fontWeight: "500",
    fontFamily: "Josefin Sans",
    fontStyle: "normal",
    color: "#1A405F"
  },
  commentDiv: {
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
    color: "white",
    padding: "12px",
    display: "flex",
    margin: "0px 10px",
    whiteSpace: "noWrap",
    textAlign: "center",
    justifyContent: "center"
  },
  sortByTypography: {
    fontFamily: "Josefin Sans",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "16px",
    color: "#1A405F"
  },
  emptyMerch: {
    objectFit: "contain",
    marginTop: "10px",
    marginLeft: "10px"
  },
  invMerchHeader: {
    fontFamily: "Josefin Sans",
    fontWeight: 700,
    fontSize: "22px",
    lineHeight: "22px",
    color: "#1A405F"
  },
  invMerchDesc: {
    fontFamily: "Josefin Sans",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "18px",
    color: "#1A405F",
    marginTop: "20px"
  },
  pendingX: {
    fontWeight: 700,
    color: "red",
    fontSize:"22px"
  }
})

export default isoAdminDashboardStyle