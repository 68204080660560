import React, { useEffect } from 'react';
import { useState } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import Filter from './components/Filter';
import Ad from './components/Ad';
import System from './components/System';
import { sendSabreFunction } from 'firebase/client';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from "react-places-autocomplete";
import moment from "moment"
import HotelInfo from './components/HotelInfo';
import { addProduct } from 'store/actions/cart';

// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';
import Hidden from "@material-ui/core/Hidden";

// @material-ui/icons
import { AiFillGift } from 'react-icons/ai';
import { ReactComponent as Diamond } from "assets/img/tuzo/icons/Diamond.svg";
import { ReactComponent as Trip } from "assets/img/tuzo/icons/TripPkg.svg";
import { ReactComponent as Computer } from "assets/img/tuzo/icons/Computer.svg";
import { ReactComponent as Experience } from "assets/img/tuzo/icons/Experience.svg";
import { ReactComponent as GiftCard } from "assets/img/tuzo/icons/GiftCard.svg";
import { ReactComponent as RaffleIcon } from "assets/img/tuzo/icons/NewRaffleIcon.svg";
import { ReactComponent as CaretLeft } from "assets/img/tuzo/icons/CaretLeft.svg";
import { ReactComponent as CaretRight } from "assets/img/tuzo/icons/CaretRight.svg";

// stylings
import Style from 'assets/jss/material-dashboard-pro-react/views/travelStyle';
import withStyles from '@material-ui/core/styles/withStyles';
import tuzoBg from "assets/img/tuzo/carouselBackground.png";
import { Dialog, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import Carousel from "react-multi-carousel";
// import "react-multi-carousel/lib/styles.css";
import "../rewards/carousel.css"
import loadingWheel from "assets/img/loader.gif";
import { sendSabreCarRental } from 'firebase/client';
import CarInfo from './components/CarInfo';
import EmptyData from './components/EmptyData';



const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 1900 },
        items: 5,
    },
    desktop: {
        breakpoint: { max: 1900, min: 1366 },
        items: 4,
    },
    tablet: {
        breakpoint: { max: 1350, min: 1024 },
        items: 3,
    },
    mobile: {
        breakpoint: { max: 1000, min: 0 },
        items: 1,
    },
};

const buttonNames = [
    "All",
    "Luxury Goods",
    "Trip Packages",
    "Electronics",
    "Experiences",
    "Gift Cards",
    "Raffles"
]

const filterPageNames = [
    "Hotels",
    "Car Rentals"
    // "Flights",
    // "Cruises",
    // "Experiences"
]

const guestsArr = [
    "1 adult",
    "2 adults"
]

const sortArr = [
    "Price: Low to High",
    "Price: High to Low",
    "Name: A to Z",
    "Name: Z to A",
]

const radiusArr = [
    "20",
    "30",
    "50",
    "100",
    "150"
]

export function TravelPage(props) {
    const { classes, addProduct, history } = props;
    const [buttonFilter, setButtonFilter] = useState("")
    const [fromDate, setFromDate] = useState("")
    const [pickUpTime, setPickUpTime] = useState("")
    const [toDate, setToDate] = useState("")
    const [dropOffTime, setDropOffTime] = useState("")
    const [guests, setGuests] = useState("1 adult")
    const [travelButtonFilter, setTravelButtonFilter] = useState("Hotels")
    const [travelData, setTravelData] = useState([])
    const [copyArr, setCopyArr] = useState([])
    const [dataLoaded, setDataLoaded] = useState(false)
    const [sortValue, setSortValue] = useState("Price: Low to High")
    const [address, setAddress] = useState("")
    const [dropOffAddress, setDropOffAddress] = useState("")
    const [city, setCity] = useState("")
    const [dropOffCity, setDropOffCity] = useState("")
    const [state, setState] = useState("")
    const [dropOffState, setDropOffState] = useState("")
    const [zipCode, setZipCode] = useState("")
    const [dropOffZipCode, setDropOffZipCode] = useState("")
    const [country, setCountry] = useState("")
    const [dropOffCountry, setDropOffCountry] = useState("")
    const [street, setStreet] = useState("")
    const [dropOffStreet, setDropOffStreet] = useState("")
    const [radius, setRadius] = useState("50")
    const [showModal, setShowModal] = useState(false)

    const getBgImage = () => {
        return tuzoBg;
    }

    const handleTravelButtonFilter = (value) => {
        setTravelButtonFilter(value)
    }

    const handleButtonFilter = (value) => {
        setButtonFilter(value)
    }

    const CustomRightArrow = ({ onClick }) => (
        <button className={classes.rightArrow} onClick={onClick}>
            <CaretRight />
        </button>
    );
    const CustomLeftArrow = ({ onClick }) => (
        <button className={classes.leftArrow} onClick={onClick}>
            <CaretLeft />
        </button>
    );

    const handleSelect = (address, placeholder) => {
        geocodeByAddress(address).then((results) => {
            const lat = results[0].geometry.location.lat();
            const lng = results[0].geometry.location.lng();
            if (placeholder === "Search your pick up address..") {
            }
            if (placeholder === "Going to" || placeholder === "Pick Up At") {
                const addressResults = results[0];
                addressResults.address_components.forEach((r) => {
                    if (r.types.includes("locality")) {
                        setCity(r.long_name);
                        sessionStorage.setItem('city', r.long_name)
                    }
                    if (r.types.includes("administrative_area_level_1")) {
                        setState(r.short_name);
                        sessionStorage.setItem('state', r.short_name)
                    }
                    if (r.types.includes("postal_code")) {
                        setZipCode(r.short_name);
                        sessionStorage.setItem('zipCode', r.short_name)
                    }
                    if (r.types.includes("country")) {
                        setCountry(r.short_name)
                        sessionStorage.setItem('country', r.short_name)
                    }
                    if (r.types.includes("route")) {
                        setStreet(r.short_name)
                        sessionStorage.setItem('street', r.short_name)
                    }
                });
                setAddress(addressResults.formatted_address);
                sessionStorage.setItem('address', addressResults.formatted_address)
            }
            // if (placeholder === "Pick Up At") {
            //     const addressResults = results[0];
            //     addressResults.address_components.forEach((r) => {
            //         if (r.types.includes("locality")) {
            //             setCity(r.long_name);
            //             sessionStorage.setItem('city', r.long_name)
            //         }
            //         if (r.types.includes("administrative_area_level_1")) {
            //             setState(r.short_name);
            //             sessionStorage.setItem('state', r.short_name)
            //         }
            //         if (r.types.includes("postal_code")) {
            //             setZipCode(r.short_name);
            //             sessionStorage.setItem('zipCode', r.short_name)
            //         }
            //         if (r.types.includes("country")) {
            //             setCountry(r.short_name)
            //             sessionStorage.setItem('country', r.short_name)
            //         }
            //         if (r.types.includes("route")) {
            //             setStreet(r.short_name)
            //             sessionStorage.setItem('street', r.short_name)
            //         }
            //     });
            //     setAddress(addressResults.formatted_address);
            //     sessionStorage.setItem('address', addressResults.formatted_address)
            // }
            if (placeholder === "Drop Off At") {
                const addressResults = results[0];
                addressResults.address_components.forEach((r) => {
                    if (r.types.includes("locality")) {
                        setDropOffCity(r.long_name);
                        sessionStorage.setItem('dropOffCity', r.long_name)
                    }
                    if (r.types.includes("administrative_area_level_1")) {
                        setDropOffState(r.short_name);
                        sessionStorage.setItem('dropOffState', r.short_name)
                    }
                    if (r.types.includes("postal_code")) {
                        setDropOffZipCode(r.short_name);
                        sessionStorage.setItem('dropOffZipCode', r.short_name)
                    }
                    if (r.types.includes("country")) {
                        setDropOffCountry(r.short_name)
                        sessionStorage.setItem('dropOffCountry', r.short_name)
                    }
                    if (r.types.includes("route")) {
                        setDropOffStreet(r.short_name)
                        sessionStorage.setItem('dropOffStreet', r.short_name)
                    }
                });
                setDropOffAddress(addressResults.formatted_address);
                sessionStorage.setItem('dropOffAddress', addressResults.formatted_address)
            }
        });
    };

    const handleHotelSort = (value) => {
        if (value === "Name: A to Z") {
            const filtered = copyArr.sort(function (a, b) {
                if (a.HotelName < b.HotelName) { return -1; }
                if (a.HotelName > b.HotelName) { return 1; }
                return 0;
            })
            setTravelData([...filtered])
        }
        if (value === "Name: Z to A") {
            const filtered = copyArr.sort(function (a, b) {
                if (b.HotelName < a.HotelName) { return -1; }
                if (b.HotelName > a.HotelName) { return 1; }
                return 0;
            })
            setTravelData([...filtered])
        }
        if (value === "Price: Low to High") {
            const filtered = travelData.sort((a, b) =>
                (a.nightlyRate.AverageNightlyRate).toString().localeCompare((b.nightlyRate.AverageNightlyRate).toString(), undefined, { numeric: true })
            )
            setTravelData([...filtered])
        }
        if (value === "Price: High to Low") {
            const filtered = travelData.sort((a, b) =>
                (b.nightlyRate.AverageNightlyRate).toString().localeCompare((a.nightlyRate.AverageNightlyRate).toString(), undefined, { numeric: true })
            )
            setTravelData([...filtered])
        }
    }

    const handleCarSort = (value) => {
        if (value === "Name: A to Z") {
            const filtered = copyArr.sort(function (a, b) {
                if (a.VendorName < b.VendorName) { return -1; }
                if (a.VendorName > b.VendorName) { return 1; }
                return 0;
            })
            setTravelData([...filtered])
        }
        if (value === "Name: Z to A") {
            const filtered = copyArr.sort(function (a, b) {
                if (b.VendorName < a.VendorName) { return -1; }
                if (b.VendorName > a.VendorName) { return 1; }
                return 0;
            })
            setTravelData([...filtered])
        }
        if (value === "Price: Low to High") {
            const filtered = travelData.sort((a, b) =>
                (a.ApproximateTotalPrice).toString().localeCompare((b.ApproximateTotalPrice).toString(), undefined, { numeric: true })
            )
            setTravelData([...filtered])
        }
        if (value === "Price: High to Low") {
            const filtered = travelData.sort((a, b) =>
                (b.ApproximateTotalPrice).toString().localeCompare((a.ApproximateTotalPrice).toString(), undefined, { numeric: true })
            )
            setTravelData([...filtered])
        }
    }

    const searchCarRental = async () => {
        setShowModal(true)
        let dataObj = {
            pickUpPostalCode: zipCode,
            pickUpCountryCode: country,
            returnPostalCode: dropOffZipCode,
            returnCountryCode: country,
            radius: Number(radius),
            pickUpDate: moment(fromDate).format("YYYY-MM-DD"),
            pickUpTime: moment(pickUpTime, "h:mm a").format("HH:mm"),
            returnDate: moment(toDate).format("YYYY-MM-DD"),
            returnTime: moment(dropOffTime, "h:mm a").format("HH:mm")
        }
        sendSabreCarRental(dataObj).then((data) => {
            if (data.data !== null) {
                const filtered = (data.data).sort((a, b) =>
                    (a.ApproximateTotalPrice).toString().localeCompare((b.ApproximateTotalPrice).toString(), undefined, { numeric: true })
                )
                setTravelData(filtered)
                setCopyArr(filtered)

            } else {
                setTravelData([])
            }
        })
            .then(() => {
                setShowModal(false)
                setDataLoaded(true)
                sessionStorage.setItem('dataLoaded', true)
            })
    }

    const searchHotel = async () => {
        sessionStorage.setItem('radius', radius)
        sessionStorage.setItem('guests', guests)
        setShowModal(true)
        let guestsNumOnly = guests.match(/\d+/)[0]
        let dataObj = {
            street: street,
            city: city,
            postalCode: zipCode,
            stateProv: "",
            countryCode: country,
            startDate: moment(fromDate).format("YYYY-MM-DD"),
            endDate: moment(toDate).format("YYYY-MM-DD"),
            adults: Number(guestsNumOnly),
            radius: Number(radius)
        }

        sendSabreFunction(dataObj)
            .then((data) => {
                if (data.data !== null) {
                    const filtered = (data.data).sort((a, b) =>
                        (a.nightlyRate.AverageNightlyRate).toString().localeCompare((b.nightlyRate.AverageNightlyRate).toString(), undefined, { numeric: true })
                    )
                    setTravelData(filtered)
                    setCopyArr(filtered)
                    sessionStorage.setItem('travelData', JSON.stringify(filtered))
                    sessionStorage.setItem('copyArr', JSON.stringify(filtered))
                }
                else {
                    setTravelData([])
                }

            })
            .then(() => {
                setShowModal(false)
                setDataLoaded(true)
                sessionStorage.setItem('dataLoaded', true)
            })
    }

    const grabPrevValues = () => {
        let prevCity = sessionStorage.getItem('city')
        let prevState = sessionStorage.getItem('state')
        let prevZipCode = sessionStorage.getItem('zipCode')
        let prevCountry = sessionStorage.getItem('country')
        let prevStreet = sessionStorage.getItem('street')
        let prevAddress = sessionStorage.getItem('address')
        let prevFromDate = sessionStorage.getItem('fromDate')
        let prevToDate = sessionStorage.getItem('toDate')
        let prevGuests = sessionStorage.getItem('guests')
        let prevRadius = sessionStorage.getItem('radius')
        let prevTravelData = JSON.parse(sessionStorage.getItem('travelData'))
        let prevDataLoaded = sessionStorage.getItem('dataLoaded')

        console.log("checking guests", prevGuests)
        if(prevDataLoaded){
            setCity(prevCity)
            setState(prevState)
            setZipCode(prevZipCode)
            setCountry(prevCountry)
            setStreet(prevStreet)
            setAddress(prevAddress)
            setFromDate(prevFromDate)
            setToDate(prevToDate)
            setGuests(prevGuests)
            setRadius(prevRadius)
            setTravelData(prevTravelData)
            if(typeof prevTravelData !== undefined){
                let prevCopyArr = sessionStorage.getItem('copyArr')
                setCopyArr(prevCopyArr)
            }
            setDataLoaded(prevDataLoaded)
        }
    }

    useEffect(() => {
        if (travelButtonFilter === "Hotels") {
            grabPrevValues()
        }
    }, [])

    return (
        <div>
            <Hidden smDown implementation="css">
                <div
                    style={{
                        backgroundImage: "url(" + getBgImage() + ")",
                        width: "100vw",
                        marginLeft: "-30px",
                        height: "100%",
                        marginTop: "-30px",
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "#274b73",
                        backgroundPhoto: "repeat",
                        backgroundSize: "contain",
                        padding: "30px 20px"
                    }}
                >
                    <GridContainer style={{ marginLeft: "30px" }}>
                        <GridItem xs={4}>
                            <div >
                                <Typography variant="h2" className={classes.rewardsBannerTitle}>
                                    Explore the Possibilities
                                </Typography >

                                <Typography variant="h2" className={classes.rewardsBannerDesc} style={{ marginTop: "15px" }}>
                                    Take a trip, pamper yourself, or buy that luxury item!
                                </Typography>
                            </div>
                        </GridItem>

                        <GridItem xs={7}>
                            <Carousel
                                autoPlay={false}
                                autoPlaySpeed={3000}
                                infinite={true}
                                swipeable={true}
                                containerClass="carousel-container"
                                dotListClass="custom-dot-list-style"
                                itemClass="carousel-item-padding-40-px"
                                draggable={false}
                                showDots={false}
                                responsive={responsive}
                                style={{ display: "flex" }}
                                arrows={true}
                                customLeftArrow={<CustomLeftArrow />}
                                customRightArrow={<CustomRightArrow />}
                            >
                                {buttonNames.map((name) => {
                                    return (
                                        <Button
                                            onClick={() => {
                                                handleButtonFilter(name)
                                                sessionStorage.clear();
                                                if (name === "All") {
                                                    history.push('/merchant/rewards')
                                                } else {
                                                    history.push(
                                                        '/merchant/rewards',
                                                        {
                                                            filterName: name === "Trip Packages" ? "Travel" : name
                                                        }
                                                    )
                                                }
                                            }}
                                            style={buttonFilter === name ? { background: "#56CA93" } : {}}
                                            className={classes.rewardsBannerButton}
                                        >
                                            {name === "All" ?
                                                <AiFillGift className={classes.icon} style={{ marginRight: "10px" }} />
                                                :
                                                name === "Luxury Goods" ?
                                                    <Diamond className={classes.icon} />
                                                    :
                                                    name === "Trip Packages" ?
                                                        <Trip className={classes.icon} />
                                                        :
                                                        name === "Electronics" ?
                                                            <Computer className={classes.icon} />
                                                            :
                                                            name === "Experiences" ?
                                                                <Experience className={classes.icon} />
                                                                :
                                                                name === "Gift Cards" ?
                                                                    <GiftCard className={classes.icon} />
                                                                    :
                                                                    <RaffleIcon className={classes.icon} />
                                            }

                                            {name}
                                        </Button>
                                    )

                                })}
                            </Carousel>
                        </GridItem>
                    </GridContainer>
                </div>

                <GridContainer>
                    <GridItem xs={12}>
                        <Filter
                            fromDate={fromDate}
                            setFromDate={setFromDate}
                            toDate={toDate}
                            setToDate={setToDate}
                            guests={guests}
                            setGuests={setGuests}
                            filterPageNames={filterPageNames}
                            travelButtonFilter={travelButtonFilter}
                            setTravelButtonFilter={setTravelButtonFilter}
                            handleTravelButtonFilter={handleTravelButtonFilter}
                            guestsArr={guestsArr}
                            searchHotel={searchHotel}
                            dataLoaded={dataLoaded}
                            handleSelect={handleSelect}
                            address={address}
                            setAddress={setAddress}
                            dropOffAddress={dropOffAddress}
                            setDropOffAddress={setDropOffAddress}
                            radius={radius}
                            setRadius={setRadius}
                            radiusArr={radiusArr}
                            pickUpTime={pickUpTime}
                            setPickUpTime={setPickUpTime}
                            dropOffTime={dropOffTime}
                            setDropOffTime={setDropOffTime}
                            searchCarRental={searchCarRental}
                        />
                    </GridItem>
                    {travelData.length === 0 && !dataLoaded ?
                        <>
                            <GridItem xs={12}>
                                <Ad />
                            </GridItem>

                            <GridItem xs={12} style={{ marginTop: "20px" }}>
                                <System />
                            </GridItem>

                        </>
                        :
                        travelData.length === 0 && dataLoaded ?
                            <GridItem xs={12}>
                                <EmptyData travelButtonFilter={travelButtonFilter} />
                            </GridItem>
                            :
                            travelData.length > 0 && travelButtonFilter === "Hotels" ?
                                <GridItem xs={12}>
                                    <HotelInfo
                                        classes={classes}
                                        travelData={travelData}
                                        city={city}
                                        sortArr={sortArr}
                                        sortValue={sortValue}
                                        setSortValue={setSortValue}
                                        fromDate={fromDate}
                                        toDate={toDate}
                                        handleHotelSort={handleHotelSort}
                                        addProduct={addProduct}
                                    />
                                </GridItem>
                                :
                                <GridItem xs={12}>
                                    <CarInfo
                                        classes={classes}
                                        travelData={travelData}
                                        city={city}
                                        sortArr={sortArr}
                                        sortValue={sortValue}
                                        setSortValue={setSortValue}
                                        fromDate={fromDate}
                                        toDate={toDate}
                                        handleHotelSort={handleHotelSort}
                                        addProduct={addProduct}
                                        handleCarSort={handleCarSort}
                                    />
                                </GridItem>
                    }

                </GridContainer>
            </Hidden>

            <Dialog
                open={showModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" style={{ fontWeight: "bold", textAlign: "center" }}>
                    Please wait...
                </DialogTitle>
                <DialogContent id="alert-dialog-description" style={{ textAlign: "center", padding: "10px" }}>
                    <center><img style={{ width: "75px", height: "75px" }} src={loadingWheel} /></center>
                </DialogContent>

            </Dialog>
        </div >
    )
}

function mapStateToProps(state) {
    return {
    };
}

function mapDispatchToProps(actions) {
    return (dispatch) => ({
        addProduct: (product) => dispatch(addProduct(product)),

    });
}

export default withRouter(compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(TravelPage));