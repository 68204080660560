/*eslint-disable*/
import React, { useState, createRef, useEffect } from "react";
import PropTypes from "prop-types";
import compose from "recompose/compose";
import { connect } from "react-redux";
import actionsForNavigation from "store/actions/navigation";
import AuthActions from 'store/actions/auth';
import { db, firebase, storage } from "firebase/client";
import UserInfo from "./components/UserInfo";
import BillingInfo from "./components/BillingInfo";
import { deleteUserPhoto } from "store/actions/auth";
import { sendIsoAdminSignUpLinkLive, sendSignUpLinkLive, sendCloudFunction, sendIrisCloudFunction } from "firebase/client";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Hidden from "@material-ui/core/Hidden";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import Slide from "@material-ui/core/Slide";
import { MenuItem, Select, Typography } from "@material-ui/core";
import envelopeLogo from "assets/img/market-me/envelopeLogo.png";
import convertLogo from "assets/img/market-me/convertLogo.png";
import houseLogo from "assets/img/market-me/houseLogo.png";
import emailLogo from "assets/img/market-me/emailLogo.png";
import phoneLogo from "assets/img/market-me/phoneLogo.png";
import userTypeLogo from "assets/img/market-me/userTypeLogo.png";

import { updateUserData, updateUserPhoto, updateUserLogo } from "store/actions/auth.js";

import { updateToken } from 'firebase/client';
import axios from 'axios';
import { getConversationRecipients, getUserPageId, sendMessage, getPageAccessToken } from 'store/actions/instagramActions';



// @material-ui/icons
import { ReactComponent as Add } from "assets/img/tuzo/icons/AddCircle.svg";
import { ReactComponent as SaveIcon } from "assets/img/tuzo/icons/SaveIcon.svg";
import { ReactComponent as Delete } from "assets/img/tuzo/icons/Delete.svg";


//styling 
import profileStyle from "assets/jss/material-dashboard-pro-react/views/profileStyle";
import tuzoBg from "assets/img/tuzo/carouselBackground.png";
import "./newProfileCalendar.css"
import { sendAdminSignUpLinkLive } from "firebase/client";


const userTypeArr = [
  "Merchant",
  "ISO",
  "Administrator"
]

const processorArr = [
  "Fiserv AccessOne",
  "IRIS"
]

export function NewProfile(props) {
  const {
    classes,
    history,
    profilePic,
  } = props;

  const EmailValidation = require('emailvalid')
  const ev = new EmailValidation({ allowFreemail: true })
  const [type, setType] = useState("Merchant")
  const [processorName, setProcessorName] = useState("Fiserv AccessOne")
  const [userBasisPts, setUserBasisPts] = useState("")
  const [signUpBonus, setSignUpBonus] = useState("")
  const [merchantId, setMerchantId] = useState("")
  const [username, setUsername] = useState("")
  const [firstNameState, setFirstNameState] = useState("");
  const [lastNameState, setLastNameState] = useState("");
  const [emailState, setEmailState] = useState("");
  const [cellPhoneState, setCellPhoneState] = useState("");
  const [secondaryPhoneState, setSecondaryPhoneState] = useState("")
  const [shippingAddressState, setShippingAddressState] = useState("")
  const [companyState, setCompanyState] = useState("");
  const [birthdayState, setBirthdayState] = useState("")
  const [whiteLabel, setWhiteLabel] = useState(false)
  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [globalEdit, setGlobalEdit] = useState(false)
  const [billingEdit, setBillingEdit] = useState(false)
  const [cardNumber, setCardNumber] = useState("")
  const [expDate, setExpDate] = useState("")
  const [nameOnCard, setNameOnCard] = useState("")
  const [billingAddress, setBillingAddress] = useState("")
  const [asDefault, setAsDefault] = useState(true)
  const [newPayment, setNewPayment] = useState(false)
  const [typeOfPayment, setTypeOfPayment] = useState({
    card: true,
    ach: false
  })
  const [apiUsername, setApiUsername] = useState("")
  const [apiPassword, setApiPassword] = useState("")
  const [imageFile, setImageFile] = useState("")
  const [imgUrl, setImgUrl] = useState("")
  const [errors, setErrors] = useState({
    username: false,
    firstName: false,
    lastName: false,
    company: false,
    points: false,
    id: false,
    email: false,
    emailTypo: false,
    apiUsername,
    apiPassword
  })


  useEffect(() => {
    if (progress === 100) {
      setUploading(false);
    }
  }, [progress]);


  function formatPhoneNumber(phoneNumber) {
    var cleaned = ("" + phoneNumber).replace(/\D/g, "");
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return "N/A";
  }


  const handleImageUpload = (event) => {
    var previewUrl = URL.createObjectURL(event.target.files[0]);
    var file = event.target.files[0];
    setImageFile(previewUrl)
    const storageRef = storage.ref("newUser");
    let fileRef;

    if (type === "Merchants" || type === "Administrator") {
      fileRef = storageRef.child("profilePic");
    } else {
      fileRef = storageRef.child("logoPic");
    }


    var uploadTask = fileRef.put(file);

    // fileRef.put(file).then((snapshot) => {
    setUploading(true)

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
        }
      },
      (error) => {
        // Handle unsuccessful uploads
      },
      (snapshot) => {
        uploadTask.snapshot.ref
          .getDownloadURL()
          .then((downloadURL) => {
            console.log("downloadURL", downloadURL);
            setImgUrl(downloadURL)
            // props.updateUserPhoto(uid, { url: downloadURL, });
          })
          .catch(() => { });
      }
    );
  };

  const getBgImage = () => {
    return tuzoBg;
  }

  const deleteImage = () => {
    setImageFile("")
  }

  const sendCustomerEmail = async (e) => {
    e.preventDefault();
    const emailCheck = ev.check(emailState)
    if (type === "Merchant" && processorName === "Fiserv AccessOne" &&
      (!userBasisPts || !companyState || !emailState || !merchantId || !username ||
        !apiUsername || !apiPassword || !firstNameState || !lastNameState || emailCheck.typo || emailCheck.valid === false)
    ) {
      let newErrs = {
        username: false,
        firstName: false,
        lastName: false,
        company: false,
        points: false,
        id: false,
        email: false,
        emailTypo: false,
        apiUsername: false,
        apiPassword: false
      }
      if (!username) {
        newErrs = { ...newErrs, username: true }
      }
      if (!apiUsername) {
        newErrs = { ...newErrs, apiUsername: true }
      }
      if (!apiPassword) {
        newErrs = { ...newErrs, apiPassword: true }
      }
      if (!firstNameState) {
        newErrs = { ...newErrs, firstName: true }
      }
      if (!lastNameState) {
        newErrs = { ...newErrs, lastName: true }
      }
      if (!companyState) {
        newErrs = { ...newErrs, company: true }
      }
      if (!userBasisPts) {
        newErrs = { ...newErrs, points: true }
      }
      if (!merchantId) {
        newErrs = { ...newErrs, id: true }
      }
      if (!emailState) {
        newErrs = { ...newErrs, email: true }
      }
      if (emailCheck.typo || emailCheck.valid === false) {
        newErrs = { ...newErrs, emailTypo: true }

      }
      setErrors({ ...newErrs })
    }
    else if (type === "Merchant" && processorName === "IRIS" &&
      (!userBasisPts || !companyState || !emailState || !merchantId || !username ||
        !firstNameState || !lastNameState || emailCheck.typo || emailCheck.valid === false)
    ) {
      let newErrs = {
        username: false,
        firstName: false,
        lastName: false,
        company: false,
        points: false,
        id: false,
        email: false,
        emailTypo: false,

      }
      if (!username) {
        newErrs = { ...newErrs, username: true }
      }
      if (!firstNameState) {
        newErrs = { ...newErrs, firstName: true }
      }
      if (!lastNameState) {
        newErrs = { ...newErrs, lastName: true }
      }
      if (!companyState) {
        newErrs = { ...newErrs, company: true }
      }
      if (!userBasisPts) {
        newErrs = { ...newErrs, points: true }
      }
      if (!merchantId) {
        newErrs = { ...newErrs, id: true }
      }
      if (!emailState) {
        newErrs = { ...newErrs, email: true }
      }
      if (emailCheck.typo || emailCheck.valid === false) {
        newErrs = { ...newErrs, emailTypo: true }

      }
      setErrors({ ...newErrs })
    }
    else if (type === "ISO" && (!userBasisPts || !companyState || !emailState || !username ||
      !firstNameState || !lastNameState || emailCheck.typo || emailCheck.valid === false)) {
      let newErrs = {
        username: false,
        firstName: false,
        lastName: false,
        company: false,
        points: false,
        email: false,
        emailTypo: false,
      }
      if (!username) {
        newErrs = { ...newErrs, username: true }
      }
      if (!firstNameState) {
        newErrs = { ...newErrs, firstName: true }
      }
      if (!lastNameState) {
        newErrs = { ...newErrs, lastName: true }
      }
      if (!companyState) {
        newErrs = { ...newErrs, company: true }
      }
      if (!userBasisPts) {
        newErrs = { ...newErrs, points: true }
      }
      if (!emailState) {
        newErrs = { ...newErrs, email: true }
      }
      if (emailCheck.typo || emailCheck.valid === false) {
        newErrs = { ...newErrs, emailTypo: true }
      }
      setErrors({ ...newErrs })
    }
    else if (type === "Administrator" && (!companyState || !emailState || !username ||
      !firstNameState || !lastNameState || emailCheck.typo || emailCheck.valid === false)) {
      let newErrs = {
        username: false,
        firstName: false,
        lastName: false,
        company: false,
        email: false,
        emailTypo: false,
      }
      if (!username) {
        newErrs = { ...newErrs, username: true }
      }
      if (!firstNameState) {
        newErrs = { ...newErrs, firstName: true }
      }
      if (!lastNameState) {
        newErrs = { ...newErrs, lastName: true }
      }
      if (!companyState) {
        newErrs = { ...newErrs, company: true }
      }
      if (!emailState) {
        newErrs = { ...newErrs, email: true }
      }
      if (emailCheck.typo || emailCheck.valid === false) {
        newErrs = { ...newErrs, emailTypo: true }
      }
      setErrors({ ...newErrs })
    }
    else {
      const docId = db.collection('temp-user').doc().id
      let tempData = {}
      if (type === "Merchant") {
        tempData = {
          processor: processorName,
          userType: "Merchants",
          username,
          firstName: firstNameState,
          lastName: lastNameState,
          companyName: companyState,
          birthday: birthdayState,
          email: emailState,
          cellPhone: cellPhoneState,
          secondaryPhone: secondaryPhoneState,
          shippingAddress: shippingAddressState,
          id: docId,
          url: imgUrl,
          userBasisPts: Number(userBasisPts),
          merchantId,
          emailSendDate: firebase.firestore.Timestamp.fromDate(new Date()),
          apiUsername,
          apiPassword
        }
      }
      else if (type === "ISO") {
        tempData = {
          userType: "IsoAdmin",
          username,
          firstName: firstNameState,
          lastName: lastNameState,
          companyName: companyState,
          birthday: birthdayState,
          email: emailState,
          cellPhone: cellPhoneState,
          secondaryPhone: secondaryPhoneState,
          shippingAddress: shippingAddressState,
          id: docId,
          url: imgUrl,
          branding: whiteLabel,
          signUpBonus,
          userBasisPts: Number(userBasisPts),
          emailSendDate: firebase.firestore.Timestamp.fromDate(new Date())
        }
      } else {
        tempData = {
          userType: "Admin",
          username,
          firstName: firstNameState,
          lastName: lastNameState,
          companyName: companyState,
          birthday: birthdayState,
          email: emailState,
          cellPhone: cellPhoneState,
          secondaryPhone: secondaryPhoneState,
          shippingAddress: shippingAddressState,
          id: docId,
          url: imgUrl,
          emailSendDate: firebase.firestore.Timestamp.fromDate(new Date())
        }
      }

      await db.collection('temp-user').doc(docId).set(tempData)
        .then(() => {
          if (type === "Merchant") {
            sendSignUpLinkLive(tempData)
          }
          else if (type === "Administrator") {
            sendAdminSignUpLinkLive(tempData)
          }
          else {
            sendIsoAdminSignUpLinkLive(tempData)
          }
        })
        .then(() => {
          if (type === "Merchant") {
            let idObj = { merchantId: merchantId, apiUsername: apiUsername, apiPassword: apiPassword }
            if (processorName === "IRIS") {
              sendIrisCloudFunction(idObj)
            }
            else {
              sendCloudFunction(idObj)
            }
          }
        })
        .then(() => {
          history.push('/admin/dashboard')
        })
    }
  }

  console.log("checking process", processorName)

  return (
    <div>
      <Hidden smDown implementation="css">
        <GridContainer>
          <GridItem xs={8} style={{ display: "flex" }}>
            <Typography variant="h2" className={classes.headerTitle}>
              New User
            </Typography>
            <div>
              <Button
                className={classes.editButton}
                style={{ background: "#1A405F", width: "128px" }}
                onClick={sendCustomerEmail}
              >
                <SaveIcon className={classes.icons} /> Save
              </Button>
              <Button
                onClick={() => history.goBack()}
                className={classes.editButton}
                style={{ background: "#E2A400", width: "143px" }}
              >
                <Delete className={classes.icons} /> Cancel
              </Button>

              <Button
                className={classes.editBillingButton}
                style={{ width: "284px", background: "#56CA93" }}
                onClick={sendCustomerEmail}
              >
                <Add className={classes.icons} /> Send Verification Email
              </Button>
            </div>
          </GridItem>

          {/* <GridItem xs={2} style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
            <div style={{ display: "flex", alignItems: "center", flexDirection: "column", marginTop: "-10px" }}>
              <Typography variant="h2" className={classes.headerDesc}>
                User Type
              </Typography>
              <Select
                style={{ marginTop: "0px", width: "150px" }}
                className={classes.selectMenuSortProfile}
                value={type}
                disableUnderline
                classes={{
                  root: classes.selectText,
                }}
                onChange={(e) => setType(e.target.value)}
              >
                {userTypeArr.map((o, i) => {
                  return (
                    <MenuItem
                      key={i}
                      value={o}
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                    >
                      {o}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
          </GridItem> */}

          {/* <GridItem xs={2} style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
            <div style={{ display: "flex", alignItems: "center", flexDirection: "column", marginTop: "-10px" }}>
              <Typography variant="h2" className={classes.headerDesc}>
                Account Number
              </Typography>
              <Typography variant="h2" className={classes.headerDesc} style={{ fontWeight: 500, fontSize: "18px", lineHeight: "18px", marginTop: "10px" }}>

              </Typography>
            </div>

          </GridItem> */}
        </GridContainer>

        <GridContainer >
          <GridItem xs={12} style={{ marginTop: "20px" }}>
            <UserInfo
              merchantId={merchantId}
              setMerchantId={setMerchantId}
              userBasisPts={userBasisPts}
              setUserBasisPts={setUserBasisPts}
              profilePic={profilePic}
              imageFile={imageFile}
              username={username}
              setUsername={setUsername}
              apiUsername={apiUsername}
              setApiUsername={setApiUsername}
              apiPassword={apiPassword}
              setApiPassword={setApiPassword}
              firstNameState={firstNameState}
              setFirstNameState={setFirstNameState}
              lastNameState={lastNameState}
              setLastNameState={setLastNameState}
              companyState={companyState}
              setCompanyState={setCompanyState}
              birthdayState={birthdayState}
              setBirthdayState={setBirthdayState}
              emailState={emailState}
              setEmailState={setEmailState}
              cellPhoneState={cellPhoneState}
              setCellPhoneState={setCellPhoneState}
              secondaryPhoneState={secondaryPhoneState}
              setSecondaryPhoneState={setSecondaryPhoneState}
              shippingAddressState={shippingAddressState}
              setShippingAddressState={setShippingAddressState}
              globalEdit={globalEdit}
              handleImageUpload={handleImageUpload}
              uploading={uploading}
              progress={progress}
              deleteImage={deleteImage}
              formatPhoneNumber={formatPhoneNumber}
              type={type}
              setType={setType}
              userTypeArr={userTypeArr}
              whiteLabel={whiteLabel}
              setWhiteLabel={setWhiteLabel}
              signUpBonus={signUpBonus}
              setSignUpBonus={setSignUpBonus}
              processorName={processorName}
              setProcessorName={setProcessorName}
              processorArr={processorArr}
              errors={errors}
            />
          </GridItem>

          {type === "Merchant" ?
            <GridItem xs={12}>
              <BillingInfo
                billingEdit={billingEdit}
                setBillingEdit={setBillingEdit}
                cardNumber={cardNumber}
                setCardNumber={setCardNumber}
                expDate={expDate}
                setExpDate={setExpDate}
                nameOnCard={nameOnCard}
                setNameOnCard={setNameOnCard}
                billingAddress={billingAddress}
                setBillingAddress={setBillingAddress}
                asDefault={asDefault}
                setAsDefault={setAsDefault}
                newPayment={newPayment}
                setNewPayment={setNewPayment}
                typeOfPayment={typeOfPayment}
                setTypeOfPayment={setTypeOfPayment}
              />
            </GridItem>
            :
            null
          }

        </GridContainer>
      </Hidden>
    </div>
  );
}

NewProfile.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {

  };
}

function mapDispatchToProps(actions) {
  return (dispatch) => ({
    edit: (key) =>
      dispatch(
        actionsForNavigation.openChild("Profile Editor", `/admin/user/editor`)
      ),
    updateUserData: (uid, newInfo) => dispatch(updateUserData(uid, newInfo)),
    updateUserPhoto: (id, document) => dispatch(updateUserPhoto(id, document)),
    deleteUserPhoto: (id) => dispatch(deleteUserPhoto(id)),
    updateUserLogo: (id, document) => dispatch(updateUserLogo(id, document)),
    logout: () => dispatch(AuthActions.startLogout()),
  });
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(profileStyle)
)(NewProfile);
