import React, { useEffect, useState } from 'react';
import DonutChart from 'react-donut-chart';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Filter from './components/Filter';
import Table from './components/Table';
//core components
import GridContainer from 'components/Grid/GridContainer';
import Button from 'components/CustomButtons/Button.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import Hidden from "@material-ui/core/Hidden";
import { Typography } from '@material-ui/core';

// styling
import withStyles from '@material-ui/core/styles/withStyles';
import Style from 'assets/jss/material-dashboard-pro-react/views/isoAdminDashboardStyle';
import loader from "assets/img/loader.gif";


// @material-ui/icons
import { AiOutlineDollar } from 'react-icons/ai';
import { Add } from '@material-ui/icons';
import { db } from 'firebase/client';
import { sendIrisGetFunction } from 'firebase/client';
import { filter } from 'lodash';


const statusArr = [
    "Total Points",
    "Name",
    "Joined"
]

const ascArr = [
    "Asc",
    "Desc"
]

const entriesArr = [
    1,
    2,
    3,
    4,
    5,
    6
]

export function Dashboard(props) {
    const {
        classes,
        history,
        userCompany
    } = props;

    const [rewardsSwitch, setRewardsSwitch] = useState("All Users")
    const [statusDropdown, setStatusDropdown] = useState("Total Points")
    const [ascDropdown, setAscDropdown] = useState("Asc")
    const [listSwitch, setListSwitch] = useState("List")
    const [searchString, setSearchString] = useState("")
    const [itemsPerPage, setItemsPerPage] = useState(6);
    const [pageNumber, setPageNumber] = useState(0);
    const [index, setIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(itemsPerPage);
    const [data, setData] = useState(false)
    const [copyArr, setCopyArr] = useState(false)
    const [transactionLen, setTransactionLen] = useState(false)
    const [emptyData, setEmptyData] = useState(false)

    const numberFormatter = new Intl.NumberFormat('en-US', {
    });

    const handlePageClick = ({ selected }) => {
        setPageNumber(selected);
        setIndex(selected * itemsPerPage);
        setEndIndex(selected * itemsPerPage + itemsPerPage);
    };

    const handleSearch = () => {
        if (searchString) {
            const filtered = copyArr.filter((f) => {
                if (
                    f.firstName && f.firstName.toLowerCase().includes(searchString.toLowerCase()) ||
                    f.lastName && f.lastName.toLowerCase().includes(searchString.toLowerCase()) ||
                    f.companyName && f.companyName.toLowerCase().includes(searchString.toLowerCase()) ||
                    f.email && f.email.toLowerCase().includes(searchString.toLowerCase()) ||
                    f.merchantId && f.merchantId.toLowerCase().includes(searchString.toLowerCase())
                ) {
                    return true
                }
            })
            if(filtered.length === 0){
                setEmptyData(true)
            }
            setData(filtered)
        } else {
            setData(copyArr)
        }
    }

    const resetEmptyData = () => {
        setEmptyData(false)
        setSearchString("")
        setData(copyArr)
    }

    const getData = () => {
        let arr = []
        let pendArr = []
        let combineArr = []

        let count = 0
        return db
            .collection('users')
            .where("IsoAdminCompanyName", "==", userCompany)
            .get()
            .then((snapshots) => {
                if (!snapshots.empty) {
                    snapshots.forEach((s) => {
                        s = s.data()
                        arr.push(s)
                    })
                    setData(arr)
                    setCopyArr(arr)
                }
                else {
                    setData(arr)
                    setCopyArr(arr)
                }
                db.collection('temp-user')
                    .where("IsoAdminCompanyName", "==", userCompany)
                    .get()
                    .then((snapshots) => {
                        if (!snapshots.empty) {
                            snapshots.forEach((s) => {
                                s = s.data()
                                let newObj = {
                                    ...s,
                                    status: "Pending"
                                }
                                pendArr.push(newObj)
                            })
                            setData(pendArr)
                            setCopyArr(pendArr)
                        }
                        else {
                            setData(pendArr)
                            setCopyArr(pendArr)
                        }
                    })
                db.collection('iso-temp-user')
                    .where("IsoAdminCompanyName", "==", userCompany)
                    .get()
                    .then((snapshots) => {
                        if (!snapshots.empty) {
                            snapshots.forEach((s) => {
                                s = s.data()
                                let newObj = {
                                    ...s,
                                    status: "Pending"
                                }
                                pendArr.push(newObj)
                            })
                            combineArr = [...arr, ...pendArr]
                            setData(combineArr)
                            setCopyArr(combineArr)
                        }
                        else {
                            combineArr = [...arr, ...pendArr]
                            setData(combineArr)
                            setCopyArr(combineArr)
                        }
                        for (let i = 0; i < combineArr.length; i++) {
                            if (combineArr[i].merchantId) {
                                db.collection('merchants-live')
                                    .where("merchantNumber", "==", combineArr[i].merchantId)
                                    .get()
                                    .then((snapshots) => {
                                        if (!snapshots.empty) {
                                            snapshots.docs.forEach((doc) => {
                                                let docId = doc.data().docId
                                                db.collection('merchants-live').doc(docId).collection("batch-summary").get()
                                                    .then((subSnapshot) => {
                                                        subSnapshot.docs.forEach((subDoc) => {
                                                            subDoc = subDoc.data()
                                                            count += Number(subDoc.transactionCount)
                                                        })
                                                        setTransactionLen(count)
                                                    })
                                            })
                                        } else {
                                            setTransactionLen("0")
                                        }

                                    })
                            }
                        }

                    })

            })

    }

    useEffect(() => {
        if (!data) {
            getData()
        }
    }, [])

    if (!data)
        return (
            <div
                style={{
                    width: "100%",
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <img style={{ width: "20%" }} src={loader} alt="..." />
            </div>
        );


    return (
        <div>
            <Hidden smDown implementation="css">
                <GridContainer>
                    <GridItem xs={2} >
                        <Typography variant="h2" className={classes.dashboardTitle} style={{ paddingBottom: "10%" }}>
                            Users
                        </Typography>

                        <Button
                            className={classes.addButton}
                            onClick={() => {
                                history.push(`/iso-admin/${userCompany.replace(/ /g, '').toLowerCase()}/add-merchant`)
                            }}
                        >
                            <Add /> Invite User
                        </Button>

                    </GridItem>

                    <GridItem xs={3}>
                        <Card className={classes.card}>
                            <GridContainer style={{ marginBottom: "15px" }}>
                                <GridItem xs={2} >
                                    <AiOutlineDollar style={{ marginTop: "5px" }} />
                                </GridItem>
                                <GridItem xs={5}>
                                    Total Merchants
                                </GridItem>
                                <GridItem
                                    xs={5}
                                    style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        fontWeight: 600,
                                        fontSize: "21px"
                                    }}
                                >
                                    {numberFormatter.format(copyArr.length)}
                                </GridItem>
                            </GridContainer >
                            <div style={{ borderTop: "2px solid #FECD2C" }} />
                            <GridContainer style={{ marginTop: "15px" }}>
                                <GridItem xs={2}>
                                    <AiOutlineDollar style={{ marginTop: "5px" }} />
                                </GridItem>
                                <GridItem xs={5}>
                                    Total Transactions
                                </GridItem>
                                <GridItem xs={5}
                                    style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        fontWeight: 600,
                                        fontSize: "21px"
                                    }}>
                                    {numberFormatter.format(transactionLen)}
                                </GridItem>
                            </GridContainer>
                        </Card>
                    </GridItem>
                    <GridItem xs={7}>
                        <Card className={classes.card}>
                            <GridContainer style={{ marginTop: "20px" }}>
                                <GridItem xs={2}>
                                    <p className={classes.circleChartDesc}>
                                        Wish List Items{"\n"}by Category
                                    </p>
                                </GridItem>
                                <GridItem xs={10}>
                                    <GridContainer>
                                        <GridItem xs={3}>
                                            <div style={{ display: "flex" }}>
                                                <DonutChart
                                                    data={[
                                                        {
                                                            // label: 'Luxury Goods',
                                                            value: 0,
                                                        },
                                                        {
                                                            label: '',
                                                            value: 0,
                                                            isEmpty: true,
                                                        },
                                                    ]}
                                                    innerRadius={.5}
                                                    outerRadius={.9}
                                                    legend={false}
                                                    interactive={false}
                                                    height={100}
                                                    width={50}
                                                    formatValues={() => `${(50 / 100 * 100).toFixed(2)}%`}
                                                    colors={["#E2A400"]}
                                                    strokeColor='#E2A400'
                                                    colorFunction={(colors, index) => colors[(index % colors.length)]}
                                                    emptyColor={"#FECD2C"}
                                                />
                                                <p className={classes.circleChartLabel}>
                                                    Luxury{"\n"}Goods
                                                </p>
                                            </div>
                                        </GridItem>
                                        <GridItem xs={3}>
                                            <div style={{ display: "flex" }}>
                                                <DonutChart
                                                    data={[
                                                        {
                                                            // label: 'Luxury Goods',
                                                            value: 0,
                                                        },
                                                        {
                                                            value: 0,
                                                            isEmpty: true,
                                                        },
                                                    ]}
                                                    innerRadius={.5}
                                                    outerRadius={.9}
                                                    legend={false}
                                                    interactive={false}
                                                    height={100}
                                                    width={50}
                                                    formatValues={() => `${(50 / 100 * 100).toFixed(2)}%`}
                                                    colors={["#E2A400"]}
                                                    strokeColor='#E2A400'
                                                    colorFunction={(colors, index) => colors[(index % colors.length)]}
                                                    emptyColor={"#FECD2C"}
                                                />
                                                <p className={classes.circleChartLabel}>
                                                    Electronics
                                                </p>
                                            </div>
                                        </GridItem>
                                        <GridItem xs={3}>
                                            <div style={{ display: "flex" }}>
                                                <DonutChart
                                                    data={[
                                                        {
                                                            // label: 'Luxury Goods',
                                                            value: 0,
                                                        },
                                                        {
                                                            value: 0,
                                                            isEmpty: true,
                                                        },
                                                    ]}
                                                    innerRadius={.5}
                                                    outerRadius={.9}
                                                    legend={false}
                                                    interactive={false}
                                                    height={100}
                                                    width={50}
                                                    formatValues={() => `${(50 / 100 * 100).toFixed(2)}%`}
                                                    colors={["#E2A400"]}
                                                    strokeColor='#E2A400'
                                                    colorFunction={(colors, index) => colors[(index % colors.length)]}
                                                    emptyColor={"#FECD2C"}
                                                />
                                                <p className={classes.circleChartLabel}>
                                                    Travel
                                                </p>
                                            </div>
                                        </GridItem>
                                        <GridItem xs={3}>
                                            <div style={{ display: "flex" }}>
                                                <DonutChart
                                                    data={[
                                                        {
                                                            // label: 'Luxury Goods',
                                                            value: 0,
                                                        },
                                                        // {
                                                        //     label: '',
                                                        //     value: 100 - 10,
                                                        //     isEmpty: true,
                                                        // },
                                                    ]}
                                                    innerRadius={.5}
                                                    outerRadius={.9}
                                                    legend={false}
                                                    interactive={false}
                                                    height={100}
                                                    width={50}
                                                    formatValues={() => `${(50 / 100 * 100).toFixed(2)}%`}
                                                    colors={["#E2A400"]}
                                                    strokeColor='#E2A400'
                                                    colorFunction={(colors, index) => colors[(index % colors.length)]}
                                                    emptyColor={"#FECD2C"}
                                                />
                                                <p className={classes.circleChartLabel}>
                                                    Gift{"\n"}Cards
                                                </p>
                                            </div>
                                        </GridItem>
                                    </GridContainer>
                                </GridItem>

                            </GridContainer >
                        </Card>
                    </GridItem>

                    <GridItem xs={12}>
                        <Card className={classes.cardTable}>
                            <GridContainer>
                                {data.length > 0 || copyArr.length > 0?
                                    <GridItem xs={12}>
                                        <Filter
                                            rewardsSwitch={rewardsSwitch}
                                            setRewardsSwitch={setRewardsSwitch}
                                            statusDropdown={statusDropdown}
                                            setStatusDropdown={setStatusDropdown}
                                            ascDropdown={ascDropdown}
                                            setAscDropdown={setAscDropdown}
                                            searchString={searchString}
                                            setSearchString={setSearchString}
                                            listSwitch={listSwitch}
                                            setListSwitch={setListSwitch}
                                            statusArr={statusArr}
                                            ascArr={ascArr}
                                            itemsPerPage={itemsPerPage}
                                            setItemsPerPage={setItemsPerPage}
                                            entriesArr={entriesArr}
                                            handleSearch={handleSearch}
                                            emptyData={emptyData}
                                            resetEmptyData={resetEmptyData}
                                        />
                                    </GridItem>
                                    :
                                    null
                                }

                                <GridItem xs={12}>
                                    <Table
                                        copyArr={copyArr}
                                        data={data}
                                        pageNumber={pageNumber}
                                        setPageNumber={setPageNumber}
                                        index={index}
                                        setIndex={setIndex}
                                        endIndex={endIndex}
                                        setEndIndex={setEndIndex}
                                        itemsPerPage={itemsPerPage}
                                        handlePageClick={handlePageClick}
                                        history={history}
                                    />
                                </GridItem>
                            </GridContainer>
                        </Card>
                    </GridItem>
                </GridContainer>

            </Hidden>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        userCompany: state.auth.user.companyName
    };
}

function mapDispatchToProps(actions) {
    return (dispatch) => ({

    });
}

export default withRouter(compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(Dashboard));
