/*eslint-disable*/
import React, { useState, createRef, useEffect } from "react";
import PropTypes from "prop-types";
import compose from "recompose/compose";
import { connect } from "react-redux";
import { db, firebase } from "firebase/client";
import UserInfo from "./components/UserInfo";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import validator from 'validator';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Hidden from "@material-ui/core/Hidden";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import { Typography } from "@material-ui/core";

import { updateUserData, updateUserPhoto, updateUserLogo, deleteUserPhoto } from "store/actions/auth.js";

// @material-ui/icons
import { ReactComponent as Delete } from "assets/img/tuzo/icons/Delete.svg";
import { CheckCircle } from "@material-ui/icons";


//styling 
import profileStyle from "assets/jss/material-dashboard-pro-react/views/profileStyle";
import { sendIsoSignUpLinkLive } from "firebase/client";
import "./calendar.css"
import { sendIrisCloudFunction } from "firebase/client";
import { sendCloudFunction } from "firebase/client";

const processorArr = [
  "Fiserv AccessOne",
  "IRIS"
]

export function NewProfile(props) {
  const {
    classes,
    history,
    profilePic,
    userCompany,
    logo,
    contactUrl,
    userEmail
  } = props;

  const EmailValidation = require('emailvalid')
  const ev = new EmailValidation({allowFreemail: true})
  const [userBasisPts, setUserBasisPts] = useState("")
  const [signUpBonus, setSignUpBonus] = useState(0)
  const [merchantId, setMerchantId] = useState("")
  const [username, setUsername] = useState("")
  const [firstNameState, setFirstNameState] = useState("");
  const [lastNameState, setLastNameState] = useState("");
  const [emailState, setEmailState] = useState("");
  const [cellPhoneState, setCellPhoneState] = useState("");
  const [secondaryPhoneState, setSecondaryPhoneState] = useState("")
  const [addressState, setAddressState] = useState("")
  const [city, setCity] = useState("")
  const [state, setState] = useState("")
  const [zipCode, setZipCode] = useState('')
  const [companyState, setCompanyState] = useState("");
  const [ein, setEin] = useState("")
  const [notes, setNotes] = useState("")
  const [processorName, setProcessorName] = useState("Fiserv AccessOne")
  const [processorPhone, setProcessorPhone] = useState("")
  const [processorEmail, setProcessorEmail] = useState("")
  const [processorMid, setProcessorMid] = useState("")
  const [errors, setErrors] = useState({
    points: false,
    processor: false,
    dba: false,
    email: false,
    emailTypo: false,
    id: false,
    username: false,
    firstName: false,
    lastName: false,
  })

  const handleSelect = (address, placeholder) => {
    geocodeByAddress(address).then((results) => {
      const lat = results[0].geometry.location.lat();
      const lng = results[0].geometry.location.lng();
      if (placeholder === "Search your pick up address..") {

      }
      if (placeholder === "Search the address..") {
        const addressResults = results[0];
        addressResults.address_components.forEach((r) => {
          if (r.types.includes("locality")) {
            setCity(r.long_name);
          }
          if (r.types.includes("administrative_area_level_1")) {
            setState(r.short_name);
          }
          if (r.types.includes("postal_code")) {
            setZipCode(r.short_name);
          }
        });
        setAddressState(addressResults.formatted_address);
      }
    });
  };


  const sendCustomerEmail = async (e) => {
    e.preventDefault();
    const emailCheck = ev.check(emailState)
    if (!userBasisPts || !companyState || !emailState || !merchantId || !username ||
      !firstNameState || !lastNameState || emailCheck.typo || emailCheck.valid === false
    ) {
      let newErrs = {
        points: false,
        processor: false,
        dba: false,
        email: false,
        id: false,
        username: false,
        firstName: false,
        lastName: false,
        emailTypo: false
      }
      if (!userBasisPts) {
        newErrs = { ...newErrs, points: true }
      }
      if (!companyState) {
        newErrs = { ...newErrs, dba: true }
      }
      if (!emailState) {
        newErrs = { ...newErrs, email: true }
      }
      if (emailCheck.typo || emailCheck.valid === false) {
        newErrs = { ...newErrs, emailTypo: true }

      }
      if (!merchantId) {
        newErrs = { ...newErrs, id: true }
      }
      if (!username) {
        newErrs = { ...newErrs, username: true }
      }
      if (!firstNameState) {
        newErrs = { ...newErrs, firstName: true }
      }
      if (!lastNameState) {
        newErrs = { ...newErrs, lastName: true }
      }
      setErrors({ ...newErrs })
    }
    else {
      const docId = db.collection('iso-temp-user').doc().id
      const tempData = {
        username,
        firstName: firstNameState,
        lastName: lastNameState,
        IsoAdminCompanyName: userCompany,
        contactUrl: contactUrl ? contactUrl : "",
        companyName: companyState,
        DBA: companyState,
        shippingAddress: addressState,
        city,
        state,
        zipCode,
        EIN: ein,
        userBasisPts: Number(userBasisPts),
        merchantId,
        email: emailState,
        id: docId,
        processor: processorName,
        processorMid,
        processorPhone,
        processorEmail,
        notes,
        signUpBonus,
        logo: logo ? logo : "",
        emailSendDate: firebase.firestore.Timestamp.fromDate(new Date()),
        IsoStaffMember: userEmail
      }
      await db.collection('iso-temp-user').doc(docId).set(tempData)
        .then(() => {
          sendIsoSignUpLinkLive(tempData)
        })
        .then(() => {
          let idObj = {merchantId: merchantId}
          if (processorName === "IRIS") {
            sendIrisCloudFunction(idObj)
          }
          else{
            sendCloudFunction(idObj)
          }
        })
        .then(() => {
          history.push(`/iso-staff/${userCompany.replace(/ /g, '').toLowerCase()}/merchants`)
        })
    }

  }

  return (
    <div>
      <Hidden smDown implementation="css">
        <GridContainer >
          <GridItem xs={8} style={{ display: "flex" }}>
            <Typography variant="h2" className={classes.headerTitle}>
              User Onboarding Form
            </Typography>
            <div>
              <Button
                className={classes.editButton}
                style={{ background: "#1A405F", width: "128px" }}
                onClick={sendCustomerEmail}
              >
                <CheckCircle className={classes.icons} /> Send
              </Button>
              <Button
                onClick={() => history.goBack()}
                className={classes.editButton}
                style={{ background: "#E2A400", width: "143px" }}
              >
                <Delete className={classes.icons} /> Cancel
              </Button>
            </div>
          </GridItem>
        </GridContainer>

        <GridContainer >
          <GridItem xs={12} style={{ marginTop: "20px" }}>
            <UserInfo
              merchantId={merchantId}
              setMerchantId={setMerchantId}
              userBasisPts={userBasisPts}
              setUserBasisPts={setUserBasisPts}
              signUpBonus={signUpBonus}
              setSignUpBonus={setSignUpBonus}
              profilePic={profilePic}
              username={username}
              setUsername={setUsername}
              firstNameState={firstNameState}
              setFirstNameState={setFirstNameState}
              lastNameState={lastNameState}
              setLastNameState={setLastNameState}
              companyState={companyState}
              setCompanyState={setCompanyState}
              ein={ein}
              setEin={setEin}
              notes={notes}
              setNotes={setNotes}
              processorName={processorName}
              setProcessorName={setProcessorName}
              processorPhone={processorPhone}
              setProcessorPhone={setProcessorPhone}
              processorEmail={processorEmail}
              setProcessorEmail={setProcessorEmail}
              processorMid={processorMid}
              setProcessorMid={setProcessorMid}
              emailState={emailState}
              setEmailState={setEmailState}
              cellPhoneState={cellPhoneState}
              setCellPhoneState={setCellPhoneState}
              secondaryPhoneState={secondaryPhoneState}
              setSecondaryPhoneState={setSecondaryPhoneState}
              addressState={addressState}
              setAddressState={setAddressState}
              handleSelect={handleSelect}
              processorArr={processorArr}
              errors={errors}
            />
          </GridItem>
        </GridContainer>
      </Hidden>
    </div>
  );
}

NewProfile.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    userCompany: state.auth.user.companyName,
    logo: state.auth.user.logo,
    contactUrl: state.auth.user?.contactUrl,
    userEmail: state.auth.user.email
  };
}

function mapDispatchToProps(actions) {
  return (dispatch) => ({
    updateUserData: (uid, newInfo) => dispatch(updateUserData(uid, newInfo)),
    updateUserPhoto: (id, document) => dispatch(updateUserPhoto(id, document)),
    deleteUserPhoto: (id) => dispatch(deleteUserPhoto(id)),
    updateUserLogo: (id, document) => dispatch(updateUserLogo(id, document)),
  });
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(profileStyle)
)(NewProfile);
