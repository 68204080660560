/*eslint-disable*/
import React, { useState, createRef, useEffect } from "react";
import PropTypes from "prop-types";
import compose from "recompose/compose";
import { connect } from "react-redux";
import actionsForNavigation from "store/actions/navigation";
import AuthActions from 'store/actions/auth';
import { db, firebase, storage } from "firebase/client";
import MerchantInfo from "./components/MerchantInfo";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Hidden from "@material-ui/core/Hidden";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import { Card, Dialog, Typography } from "@material-ui/core";

import { updateUserData, updateUserPhoto, updateUserLogo } from "store/actions/auth.js";

// @material-ui/icons
import { Close } from '@material-ui/icons';

//styling 
import profileStyle from "assets/jss/material-dashboard-pro-react/views/profileStyle";
import loader from "assets/img/loader.gif";
import tuzoBg from "assets/img/tuzo/carouselBackground.png";
import { BsCheckCircleFill } from "react-icons/bs";

export function UserProfile(props) {
  const {
    classes,
    history,
    logo,
    user,
    userCompany
  } = props;

  const merchantId = window.location.pathname.split('/')[4]
  const [merchantData, setMerchantData] = useState(false)
  const [wishListData, setWishListData] = useState(false)
  const [rewardsData, setRewardsData] = useState(false)
  const itemsPerPage = 2;
  const [pageNumber, setPageNumber] = useState(0);
  const [index, setIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(itemsPerPage);
  const [emailSent, setEmailSent] = useState(false)

  function formatPhoneNumber(phoneNumber) {
    var cleaned = ("" + phoneNumber).replace(/\D/g, "");
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return "";
  }

  const handlePageClick = ({ selected }) => {
    setPageNumber(selected);
    setIndex(selected * itemsPerPage);
    setEndIndex(selected * itemsPerPage + itemsPerPage);
  };

  const getBgImage = () => {
    return tuzoBg;
  }

  const handlePasswordReset = (e) => {
    e.preventDefault()
    props.startPasswordReset(merchantData.email)
      .then(() => {
        setEmailSent(true)
      })

  }

  useEffect(() => {
    if (!merchantData) {
      let wishListArr = [];
      let rewardsArr = [];
      var userPts = 0
      const getData = () => {
        db.collection('users').doc(merchantId).get()
          .then((snapshot) => {
            snapshot = snapshot.data()
            userPts = snapshot.tuzoPoints ? snapshot.tuzoPoints : "0"
            setMerchantData(snapshot)
          })
          .then(() => {
            db.collection('rewards-list-test')
              .where("salePricePoints", "<=", userPts)
              .get()
              .then((snapshots) => {
                if (!snapshots.empty) {
                  snapshots.forEach((doc) => {
                    rewardsArr.push(doc.data())
                  })
                  setRewardsData(rewardsArr)
                }
                else {
                  setRewardsData([])
                }
              })
          })

      }

      const getWishList = () => {
        db.collection('users').doc(merchantId).collection('wish-list').get()
          .then((snapshots) => {
            if (!snapshots.empty) {
              snapshots.forEach((doc) => {
                wishListArr.push(doc.data())
              })
              setWishListData(wishListArr)
            }
            else {
              setWishListData([])
            }
          })
      }
      getData()
      getWishList()
    }
  }, [])

  if (!merchantData || !wishListData || !rewardsData)
    return (
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img style={{ width: "20%" }} src={loader} alt="..." />
      </div>
    );

  return (
    <div>
      <Hidden mdUp implementation='css'>
        <div>
          <Typography variant="h2" className={classes.headerTitle} style={{ color: "#1A405F" }}>
            Please View Page on Desktop / Laptop
          </Typography >
        </div>
      </Hidden>

      <Hidden smDown implementation="css">
        <GridContainer>
          <GridItem xs={6} style={{ display: "flex" }}>
            <Typography variant="h2" className={classes.headerTitle}>
              {merchantData.firstName + " " + merchantData.lastName}
            </Typography>

            <Button
              onClick={handlePasswordReset}
              className={classes.editButton}
              style={{ background: "#56CA93", width: "350px" }}
            >
              <BsCheckCircleFill style={{ marginRight: "10px", marginTop: "-5px" }} /> Send Reset Password Email
            </Button>
          </GridItem>

          <GridItem xs={6} style={{ display: "flex", justifyContent: "flex-end" }}>
            <div style={{ display: "flex", alignItems: "center", flexDirection: "column", marginTop: "-10px", marginRight: "40px" }}>
              <Typography variant="h2" className={classes.headerDesc}>
                User Type
              </Typography>
              <Typography variant="h2" className={classes.headerDesc} style={{ fontWeight: 500, fontSize: "18px", lineHeight: "18px", marginTop: "10px" }}>
                {merchantData.userType.includes("Merchant") ? "Merchant" : merchantData.userType}
              </Typography>
            </div>

            <div style={{ display: "flex", alignItems: "center", flexDirection: "column", marginTop: "-10px" }}>
              <Typography variant="h2" className={classes.headerDesc}>
                MID
              </Typography>
              <Typography variant="h2" className={classes.headerDesc} style={{ fontWeight: 500, fontSize: "18px", lineHeight: "18px", marginTop: "10px" }}>
                {merchantData.merchantId}
              </Typography>
            </div>
          </GridItem>
        </GridContainer>

        <div
          style={{
            backgroundImage: "url(" + getBgImage() + ")",
            width: "100vw",
            marginLeft: "-30px",
            height: "100%",
            marginTop: "15px",
            display: "flex",
            alignItems: "center",
            backgroundColor: "#274b73",
            backgroundPhoto: "no-repeat",
            backgroundSize: "contain",
            padding: "20px"
          }}
        >
          <GridContainer style={{ alignItems: "center" }}>
            <GridItem xs={4} style={{ textAlign: "center", marginTop: "10px" }}>
              <Typography variant="h2" className={classes.profileBannerText}>
                You have <span className={classes.profileBannerPts}> {merchantData.tuzoPoints ? (merchantData.tuzoPoints).toLocaleString() : 0} </span> Tuzo points now!
              </Typography>
            </GridItem>

            <GridItem xs={4} style={{ textAlign: "center", marginTop: "10px" }}>
              <Typography variant="h2" className={classes.profileBannerText} style={{ lineHeight: "30px" }}>
                Based on your average monthly processing ({merchantData.tuzoPoints ? (merchantData.tuzoPoints).toLocaleString() : 0}), you’re on track to have:
              </Typography>
            </GridItem>

            <GridItem xs={4} style={{ marginTop: "10px", display: "flex", justifyContent: "space-evenly", textAlign: "center" }}>

              <div>
                <Typography variant="h2" className={classes.profileBannerDurationText}>
                  1 Month:
                </Typography>
                <Typography variant="h2" className={classes.profileBannerDurationPts}>
                  {merchantData.tuzoPoints ? (merchantData.tuzoPoints.toLocaleString()) : 0}
                </Typography>
              </div>

              <div>
                <Typography variant="h2" className={classes.profileBannerDurationText}>
                  1 Year:
                </Typography>
                <Typography variant="h2" className={classes.profileBannerDurationPts}>
                  {merchantData.tuzoPoints ? (merchantData.tuzoPoints * 12).toLocaleString() : 0}
                </Typography>
              </div>

              <div>
                <Typography variant="h2" className={classes.profileBannerDurationText}>
                  3 Years:
                </Typography>
                <Typography variant="h2" className={classes.profileBannerDurationPts}>
                  {merchantData.tuzoPoints ? (merchantData.tuzoPoints * 36).toLocaleString() : 0}
                </Typography>
              </div>
            </GridItem>

          </GridContainer>
        </div>

        <GridContainer >
          <GridItem xs={12} style={{ marginTop: "20px" }}>
            <MerchantInfo
              merchantId={merchantId}
              rewardsData={rewardsData}
              classes={classes}
              history={history}
              wishListData={wishListData}
              merchantData={merchantData}
              formatPhoneNumber={formatPhoneNumber}
              itemsPerPage={itemsPerPage}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              index={index}
              setIndex={setIndex}
              endIndex={endIndex}
              setEndIndex={setEndIndex}
              handlePageClick={handlePageClick}
            />
          </GridItem>
        </GridContainer>
      </Hidden>

      <Dialog
        className={classes.requestModal}
        open={emailSent}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Card className={classes.confirmModalCard}>
          <GridContainer >
            <GridItem xs={12} >
              <h5 className={classes.cardHeader}>Email has been sent to user to reset their password. </h5>
            </GridItem>
            <GridItem xs={12} style={{ justifyContent: "center", display: "flex" }}>
              <Button
                className={classes.uploadButton}
                onClick={() => {
                  setEmailSent(false)
                  history.push(`/iso-admin/${userCompany.replace(/ /g, '').toLowerCase()}/merchants`)
                }}
              >
                <Close style={{ marginRight: "10px" }} />  Close
              </Button>
            </GridItem>
          </GridContainer>
        </Card>
      </Dialog>
    </div>
  );
}

UserProfile.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    user: state.auth.user,
    userSet: state.auth.user.userSet,
    userCompany: state.auth.user.companyName,
  };
}

function mapDispatchToProps(actions) {
  return (dispatch) => ({
    edit: (key) =>
      dispatch(
        actionsForNavigation.openChild("Profile Editor", `/admin/user/editor`)
      ),
    updateUserData: (uid, newInfo) => dispatch(updateUserData(uid, newInfo)),
    updateUserPhoto: (id, document) => dispatch(updateUserPhoto(id, document)),
    deleteUserPhoto: (id) => dispatch(deleteUserPhoto(id)),
    updateUserLogo: (id, document) => dispatch(updateUserLogo(id, document)),
    deleteUserLogo: (id) => dispatch(deleteUserLogo(id)),
    logout: () => dispatch(AuthActions.startLogout()),
    startPasswordReset: (email) => dispatch(AuthActions.startPasswordReset(email)),
  });
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(profileStyle)
)(UserProfile);
