/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { db, firebase } from 'firebase/client';

//actions
import AuthActions from "store/actions/auth";

// styling
import Style from "assets/jss/material-dashboard-pro-react/views/merchantSignUpStyle";
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import { Button, Card, Checkbox, Hidden, IconButton, TextField, Typography } from '@material-ui/core';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { Close } from '@material-ui/icons';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';



export function PasswordPage(props) {
    const {
        classes,
        history,
        tempUser,
        emailSignup,
        isoLogo
    } = props;

    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [confirmTerms, setConfirmTerms] = useState(false)
    const [errors, setErrors] = useState({
        password: false,
        confirmTerms: false
    })

    const validateInput = () => {
        let newErrors = {
            password: false,
            confirmTerms: false
        }
        let isValid = true
        if ((password !== confirmPassword) || !password || !confirmPassword) {
            newErrors = ({ ...newErrors, password: true })
            isValid = false
        }
        if (!confirmTerms) {
            newErrors = ({ ...newErrors, confirmTerms: true })
            isValid = false
        }
        setErrors({ ...newErrors });

        if (!isValid) {
            return false;
        }
        return true
    }

    const completeSignUp = () => {
        const isValid = validateInput()
        if (isValid) {
            const tempId = tempUser.id;
            let merchantData = {
                ...tempUser,
                status: "Active",
                tuzoPoints: 0,
                approvedPts: 0,
                timestamp: firebase.firestore.Timestamp.fromDate(new Date())
            }
            delete merchantData.newPw
            delete merchantData.tempPw
            delete merchantData.id

            db.collection('iso-temp-user').doc(tempId).delete()
                .then(() => {
                    emailSignup({ email: merchantData.email, password: password }, merchantData)
                })
        }
        else {
            console.log("errors errors")
        }
    }

    return (
        <div style={{ overflow: "auto" }}>
            <Hidden mdUp implementation='css'>
                <GridContainer>
                    <GridItem xs={12} className={classes.gridItemFlexCenter}>
                        <img className={classes.logoImg} src={isoLogo} />
                    </GridItem>

                    <GridItem xs={12} className={classes.gridItemFlexCenter}>
                        <Card className={classes.mobileIsoMainContainer}>
                            <CardHeader className={classes.cardHeaderTitle}>
                                <GridContainer>
                                    <GridItem xs={6}>
                                        Sign Up
                                    </GridItem>

                                    <GridItem xs={6} style={{ display: "flex", justifyContent: "end" }}>
                                        <IconButton
                                            style={{ color: "#004253", marginTop: "-15px" }}
                                            onClick={() => history.push(`/auth/iso-merchant-register/${tempUser.id}`)}
                                        >
                                            <Close />
                                        </IconButton>
                                    </GridItem>
                                    <GridItem xs={12}
                                        style={{ fontSize: "14px", fontWeight: 400, color: "#929292" }}
                                    >
                                        Step 2 of 2
                                    </GridItem>
                                </GridContainer>
                            </CardHeader>
                            <CardBody className={classes.cardBody} style={{ marginTop: "30px" }}>
                                <GridContainer>
                                    <GridItem xs={12} className={classes.gridItemMobileMarginTop}>
                                        <Typography className={classes.textFieldLabel} >
                                            Create Password*
                                        </Typography>
                                        <TextField
                                            type="password"
                                            className={classes.textFieldMobile}
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            InputProps={{
                                                disableUnderline: true,
                                                classes: {
                                                    input: classes.input
                                                },
                                            }}
                                            error={errors.password}
                                            helperText={errors.password ? "Must match confirm password and cannot be empty." : ""}
                                        />
                                    </GridItem>

                                    <GridItem xs={12} className={classes.gridItemMobileMarginTop}>
                                        <Typography className={classes.textFieldLabel} style={{}}>
                                            Confirm Password*
                                        </Typography>
                                        <TextField
                                            type="password"
                                            className={classes.textFieldMobile}
                                            value={confirmPassword}
                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                            InputProps={{
                                                disableUnderline: true,
                                                classes: {
                                                    input: classes.input
                                                },
                                            }}
                                            error={errors.password}
                                            helperText={errors.password ? "Must match password and cannot be empty." : ""}
                                        />
                                    </GridItem>


                                    <GridItem xs={12}>
                                        <div style={{ marginTop: "15px" }}>
                                            <Checkbox
                                                checked={confirmTerms}
                                                onChange={() => setConfirmTerms(!confirmTerms)}
                                                style={{
                                                    color: "#1A405F",
                                                    marginLeft: "-10px",
                                                }}
                                            />
                                            <span className={classes.resendEmail}>
                                                I confirm that I have read the
                                                <span
                                                    className={classes.underlineEmail}
                                                    onClick={() => {
                                                        window.open("https://www.tuzorewards.com/privacy-policy/",
                                                            '_blank',
                                                            'noopener,noreferrer'
                                                        )
                                                    }}
                                                >
                                                    {" "}Privacy Policy{" "}
                                                </span>
                                                and{" "}
                                                <span
                                                    className={classes.underlineEmail}
                                                    onClick={() => {
                                                        window.open("https://www.tuzorewards.com/terms-of-use/",
                                                            '_blank',
                                                            'noopener,noreferrer'
                                                        )
                                                    }}
                                                >
                                                    Terms of Use
                                                </span>
                                            </span>
                                            <div className={classes.checkBoxValidation}>
                                                {errors.confirmTerms ? "Please check the box." : ""}
                                            </div>
                                        </div>
                                    </GridItem>

                                    <GridItem xs={12}>
                                        <Button
                                            className={classes.addIdButton}
                                            style={{ background: "#56CA93", width: "100%", marginTop: "10px" }}
                                            onClick={() => completeSignUp()}
                                        >
                                            Complete
                                        </Button>
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </Hidden>

            <Hidden smDown implementation='css'>
                <GridContainer>
                    <GridItem xs={12} className={classes.gridItemFlexCenter}>
                        <img className={classes.logoImg} src={isoLogo} />
                    </GridItem>

                    <GridItem xs={12} className={classes.gridItemFlexCenter} style={{ marginTop: "10px" }}>
                        <Card className={classes.isoMainContainer}>
                            <CardHeader className={classes.cardHeaderTitle}>
                                <GridContainer>
                                    <GridItem xs={6}>
                                        Sign Up
                                    </GridItem>

                                    <GridItem xs={6} style={{ display: "flex", justifyContent: "end" }}>
                                        <IconButton
                                            style={{ color: "#004253", marginTop: "-15px" }}
                                            onClick={() => history.push(`/auth/iso-merchant-register/${tempUser.id}`)}
                                        >
                                            <Close />
                                        </IconButton>
                                    </GridItem>
                                    <GridItem xs={12}
                                        style={{ fontSize: "14px", fontWeight: 400, color: "#929292" }}
                                    >
                                        Step 2 of 2
                                    </GridItem>
                                </GridContainer>
                            </CardHeader>
                            <CardBody className={classes.cardBody} style={{ marginTop: "30px" }}>
                                <GridContainer>
                                    <GridItem xs={4}>
                                        <Typography className={classes.textFieldLabel}>
                                            Create Password*
                                        </Typography>


                                    </GridItem>

                                    <GridItem xs={8}>
                                        <TextField
                                            type="password"
                                            className={classes.textField}
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            InputProps={{
                                                disableUnderline: true,
                                                classes: {
                                                    input: classes.input
                                                },
                                            }}
                                            error={errors.password}
                                            helperText={errors.password ? "Must match confirm password and cannot be empty." : ""}
                             
                                        />
                                    </GridItem>

                                    <GridItem xs={4} className={classes.gridMarginTop}>
                                        <Typography className={classes.textFieldLabel}>
                                            Confirm Password*
                                        </Typography>
                                    </GridItem>

                                    <GridItem xs={8} className={classes.gridMarginTop}>
                                        <TextField
                                            type="password"
                                            className={classes.textField}
                                            value={confirmPassword}
                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                            InputProps={{
                                                disableUnderline: true,
                                                classes: {
                                                    input: classes.input
                                                },
                                            }}
                                            error={errors.password}
                                            helperText={errors.password ? "Must match password and cannot be empty." : ""}
                     
                                        />
                                        <div style={{ marginTop: "15px" }}>
                                            <Checkbox
                                                checked={confirmTerms}
                                                onChange={() => setConfirmTerms(!confirmTerms)}
                                                style={{
                                                    color: "#1A405F",
                                                    marginLeft: "-10px",
                                                }}
                                            />
                                            <span className={classes.resendEmail}>
                                                I confirm that I have read the
                                                <span
                                                    className={classes.underlineEmail}
                                                    onClick={() => {
                                                        window.open("https://www.tuzorewards.com/privacy-policy/",
                                                            '_blank',
                                                            'noopener,noreferrer'
                                                        )
                                                    }}
                                                >
                                                    {" "}Privacy Policy{" "}
                                                </span>
                                                and{" "}
                                                <span
                                                    className={classes.underlineEmail}
                                                    onClick={() => {
                                                        window.open("https://www.tuzorewards.com/terms-of-use/",
                                                            '_blank',
                                                            'noopener,noreferrer'
                                                        )
                                                    }}
                                                >
                                                    Terms of Use
                                                </span>
                                            </span>
                                            <div className={classes.checkBoxValidation}>
                                                {errors.confirmTerms ? "Please check the box." : ""}
                                            </div>
                                        </div>
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                            <GridContainer style={{ padding: "20px" }}>
                                <GridItem xs={12} style={{ display: "flex", justifyContent: "flex-end" }}>
                                    <Button
                                        className={classes.addIdButton}
                                        style={{ background: "#56CA93", marginTop: "-10px" }}
                                        onClick={() => completeSignUp()}
                                    >
                                        Complete
                                    </Button>
                                </GridItem>
                            </GridContainer>
                        </Card>
                    </GridItem>

                    <GridItem xs={12} style={{ marginTop: "30px" }}>
                        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                            <div >
                                <span className={classes.isoTermsStyle}
                                    // onClick={handlePasswordReset}
                                    onClick={() => window.open("https://www.tuzorewards.com/terms-of-use/", "_blank", 'noopener,noreferrer')}

                                >
                                    Terms of Use
                                </span>
                            </div>

                            <div>
                                <span className={classes.isoTermsStyle}
                                    // onClick={handlePasswordReset}
                                    onClick={() => window.open("https://www.tuzorewards.com/privacy-policy/", "_blank", 'noopener,noreferrer')}
                                >
                                    Privacy Policy
                                </span>
                            </div>
                        </div>
                    </GridItem>
                </GridContainer>
            </Hidden >
        </div >
    )

}

function mapStateToProps(state, location) {
    return {
        terms: state.terms.terms,
        tempUser: location.history.location.state?.tempUser,
        isoLogo: location.history.location.state?.isoLogo
    }
}

function mapDispatchToProps(actions) {
    return (dispatch) => ({
        emailSignup: (credentials, profile) =>
            dispatch(AuthActions.startEmailSignup(credentials, profile)),
    });
}

export default withRouter(compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(PasswordPage));