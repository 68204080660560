/*eslint-disable*/
import React, { useEffect } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// mui core
import { Button, Card, Checkbox, InputAdornment, MenuItem, Select, TextField, Typography } from "@material-ui/core";

//components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

//styling
import withStyles from '@material-ui/core/styles/withStyles';
import profileStyle from "assets/jss/material-dashboard-pro-react/views/profileStyle";

export function GatewayPage(props) {
    const {
        classes,
        gatewayArr,
        gateway,
        setGateway,
        globalEdit,
        apiEdit,
        setApiEdit,
        apiInfo,
        setApiInfo
    } = props;

    const addApi = (apiName, id) => {
        if (apiInfo.some(e => e.apiType === apiName)) {
            return
        } else {
            const emptyObj = {
                apiType: apiName,
                apiKey: "",
                apiSecret: "",
                id
            }
            setApiInfo([...apiInfo, emptyObj])
        }
    }

    const maskDetails = (strKey) => {
        const mask = strKey.toLowerCase().substring(0,strKey.length - 4).replace(/[A-z0-9\W]/g,"*")
        const subStr = strKey.substring(strKey.length - 4, strKey.length)

        return mask + subStr
    }

    return (
        <div>
            <GridContainer>
                <GridItem xs={12} style={{ marginTop: "15px" }}>
                    <Card className={classes.profileCardStyling}>
                        <GridContainer>
                            <GridItem xs={7}>
                                <Typography variant="h2" className={classes.cardHeader}>
                                    Processing Gateway
                                </Typography>
                            </GridItem>

                            <GridItem xs={5} style={{ display: "flex", justifyContent: "flex-end" }}>
                                <Select
                                    style={{ width: "80%" }}
                                    className={classes.selectMenuSort}
                                    value={gateway}
                                    disableUnderline
                                    classes={{
                                        root: classes.selectText,
                                    }}
                                    onChange={(e) => {
                                        setGateway(e.target.value.type)
                                        addApi(e.target.value.type, e.target.value.id)
                                    }}
                                    displayEmpty
                                    renderValue={() => {
                                        if (gateway === "") {
                                            return <p className={classes.selectPlaceHolder}>Gateways</p>;
                                        } else {
                                            return gateway
                                        }

                                    }}
                                >
                                    <MenuItem
                                        disabled
                                        value=""
                                        classes={{
                                            root: classes.selectPlaceHolder,
                                        }}
                                    >
                                        <p
                                        >
                                            Gateways
                                        </p>
                                    </MenuItem>
                                    {gatewayArr.map((o, i) => {
                                        return (
                                            <MenuItem
                                                key={o.id}
                                                value={o}
                                                classes={{
                                                    root: classes.selectMenuItem,
                                                }}
                                            >
                                                {o.type}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </GridItem>

                            {apiInfo.map((data, index) => (
                                data.apiType === gateway ?
                                    <>
                                        <GridItem xs={12} key={index}>
                                            <GridContainer>
                                                <GridItem xs={6}>
                                                    <Typography variant="h2" className={classes.cardDesc}>
                                                        API Key/Username
                                                    </Typography>
                                                </GridItem>

                                                <GridItem xs={6}>
                                                    {globalEdit || apiEdit ?
                                                        <>
                                                            <TextField
                                                                className={classes.userInfoTextField}
                                                                value={data.apiKey}
                                                                onChange={(e) => {
                                                                    e.persist()
                                                                    setApiInfo((prev) => {
                                                                        return prev.map((item) => {
                                                                            if (item.id === index) {
                                                                                return { ...item, apiKey: e.target.value }
                                                                            } else {
                                                                                return item
                                                                            }
                                                                        })
                                                                    })
                                                                }}
                                                                InputProps={{
                                                                    disableUnderline: true,
                                                                    classes: {
                                                                        adornedEnd: classes.adournedEnd,
                                                                        input: classes.input
                                                                    },
                                                                }}
                                                            />
                                                        </>
                                                        :
                                                        <>
                                                            <Typography variant="h2" className={classes.cardDescLessWeight}>
                                                                {data.apiKey ? maskDetails(data.apiKey) : "N/A"}
                                                            </Typography>
                                                        </>
                                                    }
                                                </GridItem>
                                            </GridContainer>
                                        </GridItem>

                                        <GridItem xs={12}>
                                            <GridContainer>
                                                <GridItem xs={6}>
                                                    <Typography variant="h2" className={classes.cardDesc}>
                                                        API Secret
                                                    </Typography>
                                                </GridItem>

                                                <GridItem xs={6}>
                                                    {globalEdit || apiEdit ?
                                                        <>
                                                            <TextField
                                                                className={classes.userInfoTextField}
                                                                value={data.apiSecret}
                                                                onChange={(e) => {
                                                                    e.persist()
                                                                    setApiInfo((prev) => {
                                                                        return prev.map((item) => {
                                                                            if (item.id === index) {
                                                                                return { ...item, apiSecret: e.target.value }
                                                                            } else {
                                                                                return item
                                                                            }
                                                                        })
                                                                    })
                                                                }}
                                                                InputProps={{
                                                                    disableUnderline: true,
                                                                    classes: {
                                                                        adornedEnd: classes.adournedEnd,
                                                                        input: classes.input
                                                                    },
                                                                }}
                                                            />
                                                        </>
                                                        :
                                                        <>
                                                            <Typography variant="h2" className={classes.cardDescLessWeight}>
                                                                {data.apiSecret ? maskDetails(data.apiSecret) : "N/A"}
                                                            </Typography>
                                                        </>
                                                    }
                                                </GridItem>
                                            </GridContainer>
                                        </GridItem>
                                    </>
                                    :
                                    null
                            ))}
                        </GridContainer>
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    )
}

function mapStateToProps(state) {
    return {
    };
}

function mapDispatchToProps(actions) {
    return (dispatch) => ({
    });
}

export default withRouter(compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(profileStyle)
)(GatewayPage));